import { Dependency, HamiltonNode } from "../../../state/api/backendApiRaw";

import ReactFlow, {
  useNodesState,
  useEdgesState,
  Handle,
  Position,
  ReactFlowProvider,
} from "reactflow";

import { useLayoutEffect, useRef, useState } from "react";
import { NodeType, NodeView } from "./CodeViewUtils";

type VizNode = {
  id: string;
  type: string;
  position: { x: number; y: number };
  data: { node: HamiltonNode; nodeRole: NodeType };
  level: number;
};

type VizEdge = {
  id: string;
  source: string;
  target: string;
};

const NODE_WIDTH = 96;
const NODE_WIDTH_CLASS = "w-24";
const NODE_HEIGHT = 32;
const NODE_HEIGHT_CLASS = "h-8";

function CustomNodeComponent({
  data,
}: {
  data: { node: HamiltonNode; nodeRole: NodeType };
}) {
  console.log(data.node, data.nodeRole);
  return (
    <div>
      <NodeView type={data.nodeRole} node={data.node}></NodeView>
      <Handle
        className="!bg-dwdarkblue/70"
        type="target"
        position={Position.Left}
      />
      <Handle
        className="!bg-dwdarkblue/70"
        type="source"
        position={Position.Right}
      />
    </div>
  );
}
const clone = (obj: VizNode): VizNode => {
  return JSON.parse(JSON.stringify(obj));
};
const assignLevelsToNodes = (nodes: VizNode[]): VizNode[] => {
  const allNodes = new Map(nodes.map((node) => [node.data.node.name, node])); // set of all nodes
  // queue starts with all nodes that have are not dependend on by anything
  // E.G. none of their dependencies is in the set of all nodes
  const allDependencies = new Set(
    nodes.flatMap((node) => Object.keys(node.data.node.dependencies))
  );
  const queue = nodes.filter(
    (node) => !allDependencies.has(node.data.node.name)
  );
  // initialize visited nodes
  const visitedNodes = new Set<string>();
  // Until the queue is empty
  const out: VizNode[] = [];
  while (queue.length > 0) {
    const currentNode = queue.pop() as VizNode;
    if (visitedNodes.has(currentNode.data.node.name)) {
      continue;
    }
    visitedNodes.add(currentNode.data.node.name);
    out.push(currentNode);
    const dependencies = currentNode.data.node.dependencies as {
      [key: string]: Dependency;
    }; // We know it won't be undefined as we pop above
    Object.keys(dependencies).forEach((dependency) => {
      // If the dependency has not been visited yet
      const node = allNodes.get(dependency);
      // If we haven't visited it (or it doesn't exist, meaning we've reached a source...)
      if (node !== undefined) {
        // Add the dependency to the queue
        const copy = clone(node);
        copy.level = currentNode.level + 1 || 0;
        queue.push(copy);
      }
    });
  }
  return out;
};
const nodeTypes = { custom: CustomNodeComponent };
/**
 * Lays out function nodes with the following rules:
 * - Sources and sinks are always at the top and bottom of the graph, respectively
 * - All other nodes are laid out in topological order
 * - Nodes at the same level are laid out at the same y coordinate
 * TODO -- abstract out into a common function to calculate levels on a DAG for layout purposes
 * @param nodes Nodes to lay out
 * @param edges Edges to lay out
 * @param divHeight Height of the containing div
 * @param divWidth Width of the containing div
 */
const layoutFunctionNodes = (
  nodes: VizNode[],
  edges: VizEdge[],
  // divHeight: number,
  divWidth: number
): [VizNode[], VizEdge[], number] => {
  const nodesWithLevels = assignLevelsToNodes(nodes);
  //eslint-disable-next-line
  const maxLevel = Math.max(...nodesWithLevels.map((node) => node.level));
  const nodeWidth = divWidth / (maxLevel + 2);
  const sortedNodes = nodesWithLevels.sort((a, b) => a.level - b.level); // sort by level
  const nodesByLevel = new Array<VizNode[]>(maxLevel + 1)
    .fill([])
    .map(() => [] as VizNode[]); // array of arrays of nodes
  sortedNodes.forEach((node) => {
    nodesByLevel[node.level].push(node);
  });
  const nodesOut: VizNode[] = nodesByLevel.flatMap((nodes) => {
    // const xBounds = [0, divWidth - NODE_WIDTH];
    const nodesWithPositions = nodes.map((node, index) => {
      const y = (index + 0.5) * NODE_HEIGHT;
      const x = (maxLevel - node.level) * nodeWidth;
      // ((maxLevel - node.level) / maxLevel) * (xBounds[1] - xBounds[0]) +
      // xBounds[0];
      return {
        ...node,
        data: {
          node: node.data.node,
          nodeRole: (node.level == maxLevel
            ? "input"
            : node.level == 0
            ? "output"
            : "intermediate") as NodeType,
        },
        position: { x, y },
        type: "custom",
      };
    });
    return nodesWithPositions;
  });

  return [
    nodesOut,
    edges,
    Math.max(...nodesOut.map((node) => node.position.y)) + NODE_HEIGHT,
  ];
};

const convertToNodesAndEdges = (
  nodes: HamiltonNode[]
): [VizNode[], VizEdge[]] => {
  const vizNodes = nodes.map((node) => ({
    id: node.name,
    type: "custom",
    position: { x: 0, y: 0 },
    data: { node: node, nodeRole: "intermediate" as NodeType },
    level: 0,
  }));

  const vizEdges = nodes.flatMap((node) =>
    Object.keys(node.dependencies).map((dependencyName) => ({
      id: `${node.name}-${dependencyName}`,
      source: dependencyName,
      target: node.name,
    }))
  );

  return [vizNodes, vizEdges];
};
/**
 * Graph view -- allows for visualizing the subDAG created by the node
 * The sources/sinks will stick to the top/bottom of the graph,
 * meaning that this is really just always "behind" the code view,
 * unless it is set as the current one.
 */
export const FunctionGraphView: React.FC<{
  upstreamNodes: HamiltonNode[];
  intermediateNodes: HamiltonNode[];
  sinkNodes: HamiltonNode[];
}> = ({ upstreamNodes, intermediateNodes, sinkNodes }) => {
  const targetRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [initNodes, initEdges] = convertToNodesAndEdges([
    ...intermediateNodes,
    ...sinkNodes,
    ...upstreamNodes,
  ]);

  const [layoutedNodes, layoutedEdges, height] = layoutFunctionNodes(
    initNodes,
    initEdges,
    // dimensions.height,
    dimensions.width
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);
  console.log(layoutedNodes);
  console.log(layoutedEdges);

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.getBoundingClientRect().width,
        height: targetRef.current.getBoundingClientRect().height,
      });
    }
    setNodes(layoutedNodes);
  }, [targetRef.current]);

  return (
    <div
      className="w-full z-50 relative"
      style={{ height: `${height}px` }}
      ref={targetRef}
    >
      <ReactFlowProvider>
        <ReactFlow
          nodesDraggable={true}
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          nodeTypes={nodeTypes}
          minZoom={0.2}
          // TODO -- donate/buy pro option when we have more revenue...
          proOptions={{ hideAttribution: true }}
          // fitView
          // className="bg-dwlightblue/10"
        />
      </ReactFlowProvider>
    </div>
  );
};
