import { DagRun } from "../../../../state/api/backendApiRaw";
type RunStatus = DagRun["status"];

export const getColorFromStatus = (status: RunStatus) => {
  switch (status) {
    case "SUCCESS":
      return "bg-green-500";
    case "FAILURE":
      return "bg-red-500";
    case "RUNNING":
      return "bg-yellow-500";
    default:
      return "bg-gray-500";
  }
};

export const RunStatus = ({ status }: { status: RunStatus }) => {
  return (
    <div
      className={`rounded-full text-white w-min px-2 py-1 ${getColorFromStatus(
        status
      )}`}
    >
      {status.toLowerCase()}
    </div>
  );
};
