import React, { Context, FC, useContext, useEffect, useState } from "react";

import ReactFlow, {
  useNodesState,
  useEdgesState,
  MiniMap,
  Controls,
  Handle,
  Position,
  ReactFlowProvider,
  Panel,
  getBezierPath,
  MarkerType,
} from "reactflow";

import dagre from "dagre";

import "reactflow/dist/base.css";
// import { HamiltonNode } from "../../../state/api/backendApiRaw.js";
import { LogicalDAG, LogicalDAGBase } from "../../../hamilton/dagTypes";
import {
  DagConfig,
  HamiltonNode,
  Project,
} from "../../../state/api/backendApiRaw";

import {
  intersectArrays,
  parsePythonType,
  uniqueCombine,
} from "../../../utils";
import NodeVizConsole from "./VizConsole";
import Draggable from "react-draggable";
import {
  useDAGFromProject,
  useDAGFromProjectWithConfig,
} from "../../../state/api/api";
import { Loading } from "../../common/Loading";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { BiGitBranch } from "react-icons/bi";
import { DiGitBranch } from "react-icons/di";
import projectSlice from "../../../state/projectSlice";
import { MdOutlineExpand } from "react-icons/md";
import { Config } from "prettier";

// Selected nodes, highlighted either in red(dependencies) or lightblue(dependents)
const SelectedNodeContext = React.createContext<{
  upstreamNodes: Set<string>;
  downstreamNodes: Set<string>;
  node: string | null;
  setNodeToHighlight: (n: GroupedHamiltonNode) => void;
}>({
  upstreamNodes: new Set([]),
  downstreamNodes: new Set([]),
  node: null,
  setNodeToHighlight: () => {
    return;
  },
});

const SelectedDAGVersionContext = React.createContext<{
  selectedDAGVersions: Set<string>;
}>({
  selectedDAGVersions: new Set([]),
});

type NodeTooltipProps = {
  node: GroupedHamiltonNode | null; // When node is null, tooltip is hidden
  top: number;
  left: number;
};

const NodeTooltip = ({ node, top, left }: NodeTooltipProps) => {
  return (
    <>
      <div
        className={`bg-white shadow-md rounded-md p-4 absolute z-50 max-w-xl pointer-events-none flex flex-col gap-0.5`}
        style={{ top: `${top}px`, left: `${left}px` }}
      >
        <div className="text-xl font-bold">{node?.name}</div>
        <div className="text-sm text-gray-700">
          {node === null ? "" : parsePythonType(node.nodes[0]?.returnType)}
        </div>
        <div className="flex gap-2 text-sm text-gray-700">
          <span className="text-gray-900">Versions:</span>
          {node?.presentIn.map((version, index) => {
            return <span key={index}> {version} </span>;
          })}
        </div>
        <div className="text-sm text-gray-700">
          {node?.nodes[0]?.documentation || "No documentation yet..."}
        </div>
      </div>
    </>
  );
};

export default function CustomEdgeComponent({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  markerEnd,
}: {
  id: string;
  sourceX: number;
  sourceY: number;
  targetX: number;
  targetY: number;
  sourcePosition: Position;
  data?: { presentIn: string[] };
  targetPosition: Position;
  markerEnd?: any;
}) {
  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const { selectedDAGVersions } = useContext(SelectedDAGVersionContext);
  const presentIn = data?.presentIn || [];
  const containsAny =
    presentIn.filter((version) => selectedDAGVersions.has(version)).length > 0;

  return (
    <path
      id={id}
      style={{
        stroke: containsAny ? "rgb(156 163 175)" : "rgb(229 231 235)",
      }}
      className="react-flow__edge-path bg-gray-400"
      d={edgePath}
      markerEnd={markerEnd}
    />
  );
}

function CustomNodeComponent({ data }: { data: GroupedHamiltonNode }) {
  const { upstreamNodes, downstreamNodes, node, setNodeToHighlight } =
    useContext(SelectedNodeContext);
  const { selectedDAGVersions } = useContext(SelectedDAGVersionContext);
  const existsInSelectedDAGVersion =
    data.presentIn.filter((version) => selectedDAGVersions.has(version))
      .length > 0;
  const upstreamHighlighted = upstreamNodes.has(data.name);
  const downstreamHighlighted = downstreamNodes.has(data.name);
  const selfHighlighted = node === data.name;
  return (
    <div
      onClick={
        existsInSelectedDAGVersion
          ? () => setNodeToHighlight(data)
          : () => void 0
      }
      className={`px-4 ${
        !existsInSelectedDAGVersion
          ? "bg-gray-100"
          : upstreamHighlighted
          ? "bg-dwred"
          : downstreamHighlighted
          ? "bg-dwlightblue"
          : selfHighlighted
          ? "bg-yellow-500 text-white"
          : "bg-dwdarkblue/80"
      } py-2 shadow-sm rounded-lg  text-white w-max text-xl`}
    >
      <div className="flex w-max">
        <div className="w-max h-12 flex justify-center items-center ">
          {data.name}
        </div>
      </div>

      <Handle
        type="target"
        position={Position.Left}
        // className="w-16 !bg-teal-500"
      />
      <Handle
        type="source"
        position={Position.Right}
        // className="w-16 !bg-teal-500"
      />
    </div>
  );
}
type ContextAwareHamiltonNode = HamiltonNode & {
  presentIn: string[];
};

export type GroupedHamiltonNode = {
  name: string;
  nodes: ContextAwareHamiltonNode[];
  presentIn: string[];
};

type VizNode = {
  id: string;
  position: { x: number; y: number };
  data: GroupedHamiltonNode;
  type: string;
  targetPosition: Position;
  sourcePosition: Position;
};

type VizEdge = {
  id: string;
  source: string;
  target: string;
};

type DAGVizProps = {
  dag: LogicalDAG;
  project: Project;
  branch: string;
};

const getLayoutedElements = (
  nodes: VizNode[],
  edges: VizEdge[],
  direction = "TB"
) => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  const nodeWidth = 172;
  const nodeHeight = 36;

  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: (nodeWithPosition.x - nodeWidth / 2) * 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };
    return node;
  });

  return { nodes, edges };
};

const ConfigSelector = (props: {
  project: Project;
  configToDisplay: DagConfig;
  setConfigToDisplay: (config: DagConfig) => void;
}) => {
  // Capture our version of it
  const [currentConfig, setCurrentConfig] = useState(props.configToDisplay);
  const configValues = Object.entries(currentConfig.config).sort();
  const setConfigValue = (oldKey: string, key: string, value: string) => {
    const newConfig = { ...currentConfig, config: { ...currentConfig.config } };
    const config = newConfig.config as { [key: string]: any };
    // eslint-disable-next-line no-debugger
    config[key] = value;
    if (key !== oldKey) {
      delete config[oldKey];
    }
    newConfig.config = { ...newConfig.config, [key]: value };
    setCurrentConfig(newConfig);
  };

  return (
    <div className="flex flex-col gap-1 py-2">
      {configValues.map(([key, value], i) => {
        return (
          <div className="flex flex-row gap-1" key={key}>
            <input
              // onChange={(e) => setConfigValue(key, e.target.value, value)}
              type="text"
              defaultValue={key}
              className="block w-full rounded-md border-gray-300 shadow-sm  focus:ring-dwdarkblue sm:text-sm"
              value={key}
            />
            <input
              onBlur={(e) => {
                props.setConfigToDisplay(currentConfig);
              }}
              onChange={(e) => setConfigValue(key, key, e.target.value)}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-dwdarkblue sm:text-sm"
              type="text"
              value={value}
            />
          </div>
        );
      })}
    </div>
  );
};

const BranchSelector = (props: {
  project: Project;
  selectedBranches: Set<string>;
  currentBranch: string;
  toggleSelectedBranch: (branch: string) => void;
}) => {
  return (
    <div className="space-y-2 py-1">
      <legend className="sr-only">Notifications</legend>
      {[props.project.default_branch, ...props.project.alternate_branches]
        .sort((b1, b2) => (b1 === props.currentBranch ? -1 : 1))
        .map((branch) => {
          return (
            <>
              <div className="relative flex items-start" key={branch}>
                <div className="flex h-5 items-center">
                  <input
                    onChange={(e) => {
                      props.toggleSelectedBranch(branch);
                    }}
                    checked={props.selectedBranches.has(branch)}
                    id="comments"
                    aria-describedby="comments-description"
                    name="comments"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-dwdarkblue focus:ring-dwdarkblue"
                  />
                </div>
                <label className="ml-2 text-sm flex flex-row items-center gap-1">
                  <DiGitBranch className="text-lg" />
                  <span className="font-medium text-gray-700">{branch}</span>
                </label>
              </div>
            </>
          );
        })}
    </div>
  );
};

const convertToNodesAndEdges = (
  nodes: ContextAwareHamiltonNode[],
  selectedVersions: Set<string>
): [VizNode[], VizEdge[]] => {
  const nodeGroupings = new Map<string, ContextAwareHamiltonNode[]>();
  nodes.forEach((node) => {
    nodeGroupings.set(node.name, [
      ...(nodeGroupings.get(node.name) || []),
      node,
    ]);
  });

  // TODO -- crate a "combined Node view"
  // This will allow us to reprsent nodes with diffs in between them
  // We can pretty easily determine which ones
  const outputNodes = Array.from(nodeGroupings.entries()).map(
    ([nodeName, nodes]) => ({
      id: nodeName,
      position: { x: 0, y: 0 }, // These are overwritten later
      data: {
        nodes: nodes,
        name: nodeName,
        presentIn: uniqueCombine(...nodes.flatMap((node) => node.presentIn)),
      },
      type: "custom",
      targetPosition: Position.Bottom,
      sourcePosition: Position.Top,
    })
  );
  // .filter((groupedNode) =>
  //   groupedNode.data.presentIn.some((version) =>
  //     selectedVersions.has(version)
  //   )
  // );
  // Hacking in different IDs for edges to make sure everything works
  const outputEdges = outputNodes.flatMap((node, i) => {
    const allDependencies = uniqueCombine(
      ...node.data.nodes.flatMap((node) => {
        return Object.keys(node.dependencies);
      })
    );
    return allDependencies.map((dep) => {
      const potentialNodeSoures = nodeGroupings.get(dep) || [];
      const presentIn = intersectArrays(
        node.data.presentIn,
        potentialNodeSoures.flatMap((n) => n.presentIn)
      );
      return {
        id: `${node.data.name}-${dep}`,
        source: dep,
        target: node.data.name,
        data: { presentIn: presentIn },
        type: "custom",
        markerEnd: {
          type: MarkerType.ArrowClosed,
        },
      };
    });
  });
  return [outputNodes, outputEdges];
};

const nodeTypes = { custom: CustomNodeComponent };
const edgeTypes = { custom: CustomEdgeComponent };

export const Visualize: FC<DAGVizProps> = (props) => {
  const [selectedBranches, setSelectedBranches] = useState(
    new Set<string>([props.branch])
  );
  // TODO -- allow selecting of config
  const [config, setCurrentConfig] = useState(props.project.configs[0]);

  const toggleSelectedBranch = (branch: string) => {
    const newBranchSelections = new Set(Array.from(selectedBranches));
    if (newBranchSelections.has(branch)) {
      newBranchSelections.delete(branch);
    } else {
      newBranchSelections.add(branch);
    }
    setSelectedBranches(newBranchSelections);
  };
  const branchesToLoadFrom = Array.from(selectedBranches).sort();
  const allBranches = [
    props.project.default_branch,
    ...props.project.alternate_branches,
  ];
  // Load from branches
  const dataLoadingByBranch = allBranches.map((branch) => {
    return useDAGFromProject({
      projectId: props.project.id,
      branch: branch,
    });
    // With our current parsing strategy on the server side this is brittle
    // So this is commented out for now, but it'll be added back in when we can.
    // return useDAGFromProjectWithConfig({
    //   projectId: props.project.id,
    //   branch: branch,
    //   configName: config.name,
    //   configOverrides: JSON.stringify(config.config),
    // });
  });

  const loaded = dataLoadingByBranch
    .map((data) => data.isSuccess)
    .every((_) => _);
  const states = dataLoadingByBranch.map((i) => i.status);
  //TODO -- handle errors...
  const nodesToUse = dataLoadingByBranch
    .map((item, i) => {
      return { branch: allBranches[i], ...item }; // Add branch to it
    })
    .filter((item) => item.isSuccess)
    .flatMap((item) =>
      (item.data as LogicalDAGBase).nodes.map((node) => {
        return { ...node, presentIn: [item.branch], highlight: false };
      })
    );
  const [initNodes, initEdges] = convertToNodesAndEdges(
    nodesToUse,
    selectedBranches
  );
  const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
    initNodes,
    initEdges,
    "LR"
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);

  useEffect(() => {
    setNodes(layoutedNodes);
    setEdges(layoutedEdges);
  }, [branchesToLoadFrom.sort().join(".")]);

  const [tooltipProps, setTooltipProps] = useState<NodeTooltipProps>({
    node: null,
    top: 0,
    left: 0,
  });

  const [currentFocusNode, setCurrentFocusNode] = useState<
    GroupedHamiltonNode | undefined
  >(undefined);

  // const upstreamNodes = new Set([] as string[]); // TODO -- implement this and the next one
  // const downstreamNodes = new Set([] as string[]); // We'll need to combine all deps from the nodes, should be pretty easy
  const upstreamNodes =
    currentFocusNode === undefined
      ? new Set<string>([])
      : new Set(
          currentFocusNode.nodes
            .flatMap((node) => props.dag.getAllUpstream(node, false))
            .map((node) => node.name)
        );

  const downstreamNodes =
    currentFocusNode === undefined
      ? new Set<string>([])
      : new Set(
          currentFocusNode.nodes
            .flatMap((node) => props.dag.getAllDownstream(node, false))
            .map((node) => node.name)
        );

  // h-[90vh] is a big hack -- the problem is that reactflow needs to know a specific height
  // but parents of it don't want to give it one -- they want to react to the maximum height
  // So there's a circular dependency. 90vh gets us close enough and looks fine. This won't work if
  // its embedded somewhere else, but we can cross that bridge when we get to it.
  return (
    <>
      {tooltipProps.node && <NodeTooltip {...tooltipProps} />}

      <div className="h-[90vh] relative top-0 flex flex-row">
        <ReactFlowProvider>
          <SelectedDAGVersionContext.Provider
            value={{ selectedDAGVersions: selectedBranches }}
          >
            <SelectedNodeContext.Provider
              value={{
                upstreamNodes: upstreamNodes,
                downstreamNodes: downstreamNodes,
                node: currentFocusNode?.name || null,
                setNodeToHighlight: setCurrentFocusNode,
              }}
            >
              <div className="h-full flex-grow w-full">
                <ReactFlow
                  nodesDraggable={true}
                  nodes={nodes}
                  edges={edges}
                  onNodesChange={onNodesChange}
                  onEdgesChange={onEdgesChange}
                  onNodeMouseLeave={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setTooltipProps({
                      node: null,
                      top: 0,
                      left: 0,
                    });
                  }}
                  onNodeMouseEnter={(e, node) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setTooltipProps({
                      node: node.data,
                      top: e.clientY,
                      left: e.clientX,
                    });
                  }}
                  nodeTypes={nodeTypes}
                  edgeTypes={edgeTypes}
                  minZoom={0.2}
                  fitView
                  // TODO -- donate/buy pro option when we have more revenue...
                  proOptions={{ hideAttribution: true }}
                >
                  <MiniMap
                    pannable={true}
                    ariaLabel={null}
                    position="bottom-right"
                  />
                  {currentFocusNode && (
                    <div className="absolute w-144 z-50 right-0 h-max m-2">
                      <Draggable>
                        <div>
                          <NodeVizConsole
                            node={currentFocusNode}
                            dag={props.dag}
                          />
                        </div>
                      </Draggable>
                    </div>
                  )}
                </ReactFlow>
              </div>
            </SelectedNodeContext.Provider>
          </SelectedDAGVersionContext.Provider>
          <Panel position="top-left">
            <Draggable>
              <ConfigSettings
                project={props.project}
                branch={props.branch}
                toggleSelectedBranch={toggleSelectedBranch}
                selectedBranches={selectedBranches}
                setCurrentConfig={setCurrentConfig}
              />
            </Draggable>
          </Panel>
        </ReactFlowProvider>
      </div>
    </>
  );
};

const ConfigSettings = (props: {
  project: Project;
  branch: string;
  toggleSelectedBranch: (branch: string) => void;
  selectedBranches: Set<string>;
  setCurrentConfig: (config: DagConfig) => void;
}) => {
  const [expanded, setExpanded] = useState(true);
  return (
    <div className="bg-white p-3 rounded-md border-gray-300 border shadow flex flex-col gap-1 w-96">
      <div className="justify-between flex flex-row sticky right-0 top-0">
        <h1>DAG Parameters</h1>
        <MdOutlineExpand
          className="hover:scale-125 text-lg"
          onClick={() => setExpanded(!expanded)}
        />
      </div>
      {expanded ? (
        <>
          <h1 className="text-gray-700">Branches</h1>
          <BranchSelector
            project={props.project}
            currentBranch={props.branch}
            toggleSelectedBranch={props.toggleSelectedBranch}
            selectedBranches={props.selectedBranches}
          />
          <h1 className="text-gray-700">Configuration</h1>
          <ConfigSelector
            project={props.project}
            configToDisplay={props.project.configs[0]}
            setConfigToDisplay={(config) => props.setCurrentConfig(config)}
          />
        </>
      ) : (
        <div></div>
      )}
    </div>
  );
};
