import React from "react";
import { useRunsFromProject } from "../../../state/api/api";
import { Project } from "../../../state/api/backendApiRaw";
import { Loading } from "../../common/Loading";
import { RunsTable } from "./RunsTable";

const Runs: React.FC<{ project: Project }> = ({ project }) => {
  // TODO -- figure out what happens if the project hasn't loaded yet
  const runs = useRunsFromProject({ projectId: project.id });
  if (runs.error) {
    return <span>error</span>;
  } else if (runs.isLoading || runs.isFetching) {
    return <Loading />;
  } else if (runs.isUninitialized || runs.data === undefined) {
    return <span>uninitialized, figure out why this is happening...</span>;
  }
  return <RunsTable runs={runs.data} />;
};

export default Runs;
