import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
// import Dashboard from "./components/dashboard/Dashboard";
import Projects from "./components/dashboard/Projects";
import { CodeOutlet } from "./components/dashboard/Code/CodeOutlet";
import { CatalogueOutlet } from "./components/dashboard/Catalogue/CatalogueOutlet";
import { VisualizeOutlet } from "./components/dashboard/Visualize/VisualizeOutlet";
import { MaterializeOutlet } from "./components/dashboard/Materialize/MaterializeOutlet";
import { ReportOutlet } from "./components/dashboard/Report/ReportOutlet";
import { RunsOutlet } from "./components/dashboard/Runs/RunsOutlet";
import { useEffect } from "react";
import { useAuthInfo } from "@propelauth/react";
import { useAppDispatch } from "./state/hooks";
import { setAuth } from "./state/authSlice";
import Dashboard from "./components/dashboard/Dashboard";
import Run from "./components/dashboard/Runs/Run/Run";
import EditMaterialize from "./components/dashboard/Materialize/EditMaterialize";
import NewMaterialize from "./components/dashboard/Materialize/NewMaterialize";

export const App = () => {
  const authInfo = useAuthInfo();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setAuth(authInfo));
    // Instead, we should be using the raw js library to grab the auth token
    // Or, figure out another way to have auth depend on state
  }, [authInfo]);
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/dashboard" element={<Dashboard />}>
            <Route index path="code" element={<CodeOutlet />} />
            <Route path="visualize" element={<VisualizeOutlet />} />
            <Route path="runs" element={<RunsOutlet />} />
            <Route path="runs/:runId" element={<Run />} />
            <Route path="catalogue" element={<CatalogueOutlet />} />
            <Route path="materialize" element={<MaterializeOutlet />}></Route>
            <Route
              path="materialize/edit/:materializeId"
              element={<EditMaterialize />}
            />
            <Route path="materialize/new" element={<NewMaterialize />} />
            <Route path="report" element={<ReportOutlet />} />
          </Route>
          <Route path="/projects" element={<Projects />} />
          <Route path="/" element={<Navigate to="/dashboard/code" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};
