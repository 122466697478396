import React from "react";
import { LogicalDAG } from "../../../../hamilton/dagTypes";
import { HamiltonNode, Project } from "../../../../state/api/backendApiRaw";
import Select from "react-select";
import { classNames, getPythonTypeIcon } from "../../../../utils";
import { IconType } from "react-icons/lib";
import { DropdownSelector } from "../../../common/Dropdown";

import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { PlusIcon } from "@heroicons/react/24/outline";

/**
 * This whole file is kind of junky and almost totally for show.
 * We really haven't dug into the product requirements enough yet,
 * so this is purely a mock-up. Thus the logic is a little bit messy.
 *
 * Should customers find this valuable, the logic will do something as follows:
 * 1. Load all available materialization specs from the server
 *      -> This would have some basics that we support
 *      -> As well as some that the organization enables
 *      -> Which could include custom ones
 *
 * 2. Have everything dynamically generated from the results of (1)
 *
 * It'll be much easier to craft this logic when we have actual requirements.
 */
type SelectedNodes = {
  value: string;
  label: string;
  icon: IconType;
};

type ArtifactGroup = {
  name: string;
};

type StorageEngines = "s3" | "snowflake" | "/dev/null";

const getFieldsForStorageEngine = (storageEngine: StorageEngines | null) => {
  switch (storageEngine) {
    case "s3":
      return [
        { name: "Bucket", value: "bucket" },
        { name: "Key", value: "key" },
      ];
    case "snowflake":
      return [
        { name: "Database", value: "database" },
        { name: "Schema", value: "schema" },
        { name: "Table", value: "table" },
      ];
    case "/dev/null":
      return [];
    case null:
      return [];
  }
};

type ModelStorageEngines = "s3" | "mlflow" | "/dev/null";

const getFieldsForModelStorageEngine = (
  storageEngine: ModelStorageEngines | null
) => {
  switch (storageEngine) {
    case "s3":
      return [
        { name: "Bucket", value: "bucket" },
        { name: "Key", value: "key" },
      ];
    case "mlflow":
      return [
        // TODO -- add in model table inference...
        { name: "Model Name", value: "model_name" },
      ];
    case "/dev/null":
      return [];
    case null:
      return [];
  }
};

type ArtifactOption = {
  name: "table" | "model";
  description: string;
};

const artifactOptions: ArtifactOption[] = [
  {
    name: "table",
    description: "Create a table in a database from one or more DAG nodes",
  },
  {
    name: "model",
    description: "Save a model from your ETL.",
  },
];

const DeleteButton = (props: { deleteMe: () => void }) => {
  return (
    <button
      className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-dwred py-2 px-4 text-sm font-medium
     text-white shadow-sm hover:bg-dwred/80 focus:outline-none focus:ring-2 focus:ring-dwred focus:ring-offset-2"
      onClick={props.deleteMe}
    >
      -
    </button>
  );
};

export const CreateNewMenu = (props: {
  setSelected: (option: ArtifactOption) => void;
}) => {
  return (
    <Listbox value={null} onChange={props.setSelected}>
      {({ open }) => (
        <>
          <Listbox.Label className="sr-only">
            {" "}
            Change published status{" "}
          </Listbox.Label>
          <div className="relative">
            <div className="inline-flex divide-x divide-gray-500 rounded-md shadow-sm">
              <div className="inline-flex divide-x divide-gray-500 rounded-md shadow-sm">
                <div className="inline-flex items-center rounded-l-md border border-transparent bg-dwlightblue py-2 pl-3 pr-4 text-white shadow-sm">
                  <p className="ml-2.5 text-sm font-medium">Add Artifact</p>
                </div>
                <Listbox.Button className="inline-flex items-center rounded-l-none rounded-r-md bg-dwlightblue p-2 text-sm font-medium text-white hover:bg-dwdarkblue focus:outline-none focus:ring-2 focus:ring-dwdarkblue focus:ring-offset-2 focus:ring-offset-gray-50">
                  <span className="sr-only">Change published status</span>
                  <PlusIcon className="h-5 w-5 text-white" aria-hidden="true" />
                </Listbox.Button>
              </div>
            </div>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute right-0 z-10 mt-2 w-72 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {artifactOptions.map((option) => (
                  <Listbox.Option
                    key={option.name}
                    className={({ active }) =>
                      classNames(
                        active
                          ? "text-white bg-dwdarkblue/80"
                          : "text-gray-900",
                        "cursor-default select-none p-4 text-sm"
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <div className="flex flex-col">
                        <div className="flex justify-between">
                          <p
                            className={
                              selected ? "font-semibold" : "font-normal"
                            }
                          >
                            {option.name}
                          </p>
                          {selected ? (
                            <span
                              className={
                                active ? "text-white" : "text-indigo-500"
                              }
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </div>
                        <p
                          className={classNames(
                            active ? "text-indigo-200" : "text-gray-500",
                            "mt-2"
                          )}
                        >
                          {option.description}
                        </p>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

const StorageEngineSpecificOptionsInput: React.FC<{
  storageEngine: StorageEngines | null;
}> = (props) => {
  const fields = getFieldsForStorageEngine(props.storageEngine);
  {
    return (
      <div className="space-y-2 md:col-span-2 mt-3">
        <>
          {fields.map((field, index) => {
            return (
              <div key={index}>
                <label className="block text-sm font-medium text-gray-700 max-w-96 mt-1">
                  {field.name}
                </label>
                <div className="mt-0">
                  <input
                    type="text"
                    defaultValue=""
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-dwdarkblue focus:ring-dwdarkblue disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 sm:text-sm"
                    placeholder=""
                  />
                </div>
              </div>
            );
          })}
        </>
      </div>
    );
  }
};

const ModelStorageEngineSpecificOptionsInput: React.FC<{
  storageEngine: ModelStorageEngines | null;
}> = (props) => {
  const fields = getFieldsForModelStorageEngine(props.storageEngine);
  {
    return (
      <div className="space-y-2 md:col-span-2 mt-3">
        <>
          {fields.map((field, index) => {
            return (
              <div key={index}>
                <label className="block text-sm font-medium text-gray-700 max-w-96 mt-1">
                  {field.name}
                </label>
                <div className="mt-0">
                  <input
                    type="text"
                    defaultValue=""
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-dwdarkblue focus:ring-dwdarkblue disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 sm:text-sm"
                    placeholder=""
                  />
                </div>
              </div>
            );
          })}
        </>
      </div>
    );
  }
};

type TableMaterializationConfig = {
  kind: "table";
  nodes: string[];
  storageEngine: StorageEngines | null;
  storageEngineOptions: { [key: string]: string };
};

type ModelMaterializationConfig = {
  kind: "model";
  node: string | null;
  storageEngineOptions: { [key: string]: string };
  storageEngine: ModelStorageEngines | null;
};

const TableMaterializationSelector: React.FC<{
  nodes: HamiltonNode[];
  configValue: TableMaterializationConfig;
  setConfigValue: (configValue: TableMaterializationConfig) => void;
  deleteMe: () => void;
}> = (props) => {
  const usableNodes = filterToSaveableNodes(props.nodes, "table");
  const [selectedNodes, setSelectedNodes] = React.useState<
    (SelectedNodes | null)[]
  >([]);
  const [storageEngine, setStorageEngine] =
    React.useState<StorageEngines | null>(null);

  const nodeChoices: SelectedNodes[] = usableNodes.map((node) => {
    return {
      value: node.name,
      label: node.name,
      icon: getPythonTypeIcon(node.returnType),
    };
  });
  console.log(storageEngine);
  return (
    <div className="flex flex-col gap-3">
      <div>
        <div className="flex flex-row justify-between items-end">
          <label className="block text-sm font-medium text-gray-700 max-w-96">
            Columns/dataframes to join
          </label>
          <DeleteButton deleteMe={props.deleteMe} />
        </div>
        <div className="mt-1">
          <Select
            defaultValue={[]}
            isMulti
            name="nodes"
            options={nodeChoices}
            // const onChange = (option: readonly Option[], actionMeta: ActionMeta<Option>) => {

            onChange={(option, actionMeta) => {
              setSelectedNodes(option as SelectedNodes[]);
            }}
            value={selectedNodes}
          ></Select>
        </div>
      </div>
      <div>
        <div className="mt-1">
          <DropdownSelector
            choices={[
              { name: "snowflake", value: "snowflake" },
              { name: "s3", value: "s3" },
              { name: "/dev/null", value: "/dev/null" },
            ]}
            setCurrentChoice={(choice) => {
              console.log(choice);
              setStorageEngine(choice.value as StorageEngines);
            }}
            currentChoice={{ name: storageEngine, value: storageEngine } || ""}
            title="Where to store"
          />
          <StorageEngineSpecificOptionsInput storageEngine={storageEngine} />
        </div>
      </div>
    </div>
  );
};

const ModelMaterializationSelector: React.FC<{
  nodes: HamiltonNode[];
  configValue: ModelMaterializationConfig;
  setConfigValue: (configValue: ModelMaterializationConfig) => void;
  deleteMe: () => void;
}> = (props) => {
  const [selectedNode, setSelectedNode] = React.useState<SelectedNodes | null>(
    null
  );
  const usableNodes = filterToSaveableNodes(props.nodes, "model");
  const [storageEngine, setStorageEngine] =
    React.useState<ModelStorageEngines | null>(null);

  const nodeChoices: SelectedNodes[] = usableNodes.map((node) => {
    return {
      value: node.name,
      label: node.name,
      icon: getPythonTypeIcon(node.returnType),
    };
  });
  return (
    <div className="flex flex-col gap-3">
      <div>
        <div className="flex flex-row justify-between items-end">
          <label className="block text-sm font-medium text-gray-700 max-w-96">
            Node that produces model
          </label>
          <DeleteButton deleteMe={props.deleteMe} />
        </div>
        <div className="mt-1">
          <Select
            noOptionsMessage={() => "No nodes that produce models"}
            name="nodes"
            options={nodeChoices}
            onChange={(option, actionMeta) => {
              setSelectedNode(option);
            }}
            value={selectedNode}
          ></Select>
        </div>
      </div>
      <div>
        <div className="mt-1">
          <DropdownSelector
            choices={[
              { name: "mlflow", value: "mlflow" },
              { name: "s3", value: "s3" },
              { name: "/dev/null", value: "/dev/null" },
            ]}
            setCurrentChoice={(choice) => {
              console.log(choice);
              setStorageEngine(choice.value);
            }}
            currentChoice={{ name: storageEngine, value: storageEngine } || ""}
            title="Where to store"
          />
          <ModelStorageEngineSpecificOptionsInput
            storageEngine={storageEngine}
          />
        </div>
      </div>
    </div>
  );
};

const filterToSaveableNodes = (
  nodes: HamiltonNode[],
  kind: "table" | "model"
) => {
  let correctTypes: HamiltonNode[] = [];
  if (kind === "table") {
    correctTypes = nodes.filter((node) => {
      return (
        node.returnType.typeName.includes("Series") ||
        node.returnType.typeName.includes("DataFrame")
      );
    });
  } else if (kind === "model") {
    correctTypes = nodes.filter((node) => {
      return node.returnType.typeName.includes("Model");
    });
  }

  const seen = new Set<string>();
  const unique = [
    ...correctTypes.filter((node) => {
      const duplicate = seen.has(node.name);
      seen.add(node.name);
      return !duplicate;
    }),
  ];
  return unique;
};

const Artifacts: React.FC<{ project: Project; dag: LogicalDAG }> = (props) => {
  const [materializations, setMaterializations] = React.useState<
    (TableMaterializationConfig | ModelMaterializationConfig)[]
  >([]);
  return (
    <>
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Artifacts
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Choose which artifacts to materialize, and where to save them...
          </p>
        </div>
        <div className="mt-5 space-y-6 md:col-span-2 md:mt-0">
          <div className="flex justify-end">
            <CreateNewMenu
              setSelected={(option) => {
                setMaterializations([
                  ...materializations,
                  option.name === "table"
                    ? {
                        nodes: [],
                        storageEngine: null,
                        storageEngineOptions: {},
                        kind: "table",
                      }
                    : {
                        node: null,
                        storageEngine: null,
                        storageEngineOptions: {},
                        kind: "model",
                      },
                ]);
              }}
            />
          </div>
          {materializations.map((materialization, index) => {
            const deleteMaterialization = (i: number) => {
              setMaterializations(
                materializations.filter((m, i) => i != index)
              );
            };
            return materialization.kind === "table" ? (
              <TableMaterializationSelector
                key={index}
                nodes={props.dag.nodes}
                configValue={materialization as TableMaterializationConfig}
                setConfigValue={(configValue) => {
                  setMaterializations(
                    materializations.splice(index, 1, configValue)
                  );
                }}
                deleteMe={() => {
                  deleteMaterialization(index);
                }}
              />
            ) : (
              <ModelMaterializationSelector
                key={index}
                nodes={props.dag.nodes}
                configValue={materialization as ModelMaterializationConfig}
                setConfigValue={(configValue) => {
                  setMaterializations(
                    materializations.splice(index, 1, configValue)
                  );
                }}
                deleteMe={() => {
                  deleteMaterialization(index);
                }}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Artifacts;
