import { Link } from "react-router-dom";
import { mockMaterializationConfigs } from "./mockData";
import { MaterializationConfig } from "./types";

type RenderProps = {
  materializationConfig: MaterializationConfig;
};

const cols = [
  {
    name: "Name",
    render: (props: RenderProps) => (
      <div>{props.materializationConfig.name}</div>
    ),
  },
  {
    name: "Description",
    render: (props: RenderProps) => (
      <div>{props.materializationConfig.description}</div>
    ),
  },
  {
    name: "Created",
    render: (props: RenderProps) => (
      <div>{props.materializationConfig.created.toLocaleDateString()}</div>
    ),
  },
  {
    name: "Updated",
    render: (props: RenderProps) => (
      <div>{props.materializationConfig.updated.toLocaleDateString()}</div>
    ),
  },
  {
    name: "Last Run",
    render: (props: RenderProps) => (
      <div>{props.materializationConfig.runHistory[0].runId}</div>
    ),
  },
];

export const Materialize = () => {
  const materializeConfigs = mockMaterializationConfigs;
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            Materialization Configs
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Configurations for executing your Hamilton DAGs in production.
            Scoped to the current project.
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <Link
            to={"/dashboard/materialize/new"}
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-dwlightblue px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-dwdarkblue focus:outline-none focus:ring-2 focus:ring-dwdarkblue focus:ring-offset-2 sm:w-auto"
          >
            Create New
          </Link>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  {cols.map((col, index) => {
                    const classNames =
                      index === 0
                        ? "py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                        : "px-3 py-3.5 text-left text-sm font-semibold text-gray-900";

                    return (
                      <th scope="col" key={col.name} className={classNames}>
                        {col.name}
                      </th>
                    );
                  })}

                  <th
                    scope="col"
                    className="relative py-3.5 pl-3 pr-4 sm:pr-6 md:pr-0"
                  >
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                <>
                  {materializeConfigs.map((materializationConfig, index) => {
                    return (
                      <tr key={index}>
                        {cols.map((col, index) => {
                          const classNames =
                            index == 0
                              ? "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 md:pl-0"
                              : "whitespace-nowrap py-4 px-3 text-sm text-gray-500";
                          return (
                            <td className={classNames} key={index}>
                              <col.render
                                materializationConfig={materializationConfig}
                              />
                            </td>
                          );
                        })}
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 md:pr-0">
                          <Link
                            to={`/dashboard/materialize/edit/${materializationConfig.id}`}
                            className="text-dwlightblue hover:text-dwdarkblue"
                          >
                            Edit
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
