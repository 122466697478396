import { HamiltonFunction, HamiltonNode } from "../state/api/backendApiRaw";

type JSONValue = { [key: string]: string }; // TODO -- fix the above when this issue is fixed...
// https://github.com/immerjs/immer/pull/990

export interface LogicalDAGBase {
  functions: HamiltonFunction[];
  nodes: HamiltonNode[];
  config: JSONValue | null;
  DAGRoot: string[]; // Path from within repository root -- not 100% sure this should go here but it'll help for now
}

/**
 * Tells whether a node belongs to a function
 * @param fn  Function to check
 * @param node Node Node to check
 * @returns Whether a node belongs to a function
 */
const nodeBelongsToFunction = (fn: HamiltonFunction, node: HamiltonNode) => {
  return node.functionIdentifier.join(".") == [...fn.module, fn.name].join(".");
};

/**
 * Does a group-map of nodes. This is useful as more than one might have the same name
 * @param nodes Nodes to group
 * @returns A map of node name -> list of implementations
 */
export const groupMap = (nodes: HamiltonNode[]) => {
  /**
   * Groups nodes by their name
   * Note that this is required as we produce all possible supersets of nodes
   */
  const map = new Map<string, HamiltonNode[]>();
  for (const node of nodes) {
    const key = node.name;
    if (map.has(key)) {
      map.get(key)?.push(node);
    } else {
      map.set(key, [node]);
    }
  }
  return map;
};

/**
 * A DAG with convenience functions
 */
export interface LogicalDAG extends LogicalDAGBase {
  /**
   * Gets all nodes implied by a function (upstream, intermediate, and sink nodes)
   */
  getImpliedNodes: (fn: HamiltonFunction) => {
    upstream: HamiltonNode[];
    sinks: HamiltonNode[];
    intermediate: HamiltonNode[];
  };
  /**
   * Gets the function that created a node
   */
  getFunctionCreatingNode: (node: HamiltonNode) => HamiltonFunction | null;
  /**
   * Gets all upstream nodes of a node
   */
  getAllUpstream: (node: HamiltonNode, includeSelf: boolean) => HamiltonNode[];

  /**
   * Gets all downstream nodes of a node
   */
  getAllDownstream: (
    currentFocusNode: HamiltonNode,
    arg1: boolean
  ) => HamiltonNode[];

  getNeighbors: (node: HamiltonNode) => {
    upstream: HamiltonNode[];
    downstream: HamiltonNode[];
  };
}

export const filterUnique = (nodes: HamiltonNode[]) => {
  /**
   * Filters hamilton nodes so we only have one with each name
   */
  const seen = new Set<string>();
  const out: HamiltonNode[] = [];
  nodes.forEach((node) => {
    if (!seen.has(node.name)) {
      out.push(node);
      seen.add(node.name);
    }
  });
  return out;
};

/**
 * Implementation of logical DAG class.
 */
class LogicalDAGImpl implements LogicalDAG {
  functions: HamiltonFunction[];
  nodes: HamiltonNode[];
  config: JSONValue | null;
  DAGRoot: string[];

  constructor(dag: LogicalDAGBase) {
    this.functions = dag.functions;
    this.nodes = dag.nodes;
    this.config = dag.config;
    this.DAGRoot = dag.DAGRoot;
  }
  getNeighbors = (node: HamiltonNode) => {
    const upstream = this.nodes.filter((n) =>
      //eslint-disable-next-line
      node.dependencies.hasOwnProperty(n.name)
    );
    const downstream = this.nodes.filter((n) =>
      //eslint-disable-next-line
      n.dependencies.hasOwnProperty(node.name)
    );
    return { upstream, downstream };
  };

  getImpliedNodes = (fn: HamiltonFunction) => {
    // First, get all possible implementations of a node
    const allNodeNameMaps = groupMap(this.nodes);
    const allNodesProducedByFunction = new Map(
      this.nodes
        .filter((node) => nodeBelongsToFunction(fn, node))
        .map((node) => [node.name, node])
    );

    // get all the possible dependencies of a node
    const allPossibleDependencies = Array.from(
      allNodesProducedByFunction.values()
    )
      .flatMap(
        (node) => Object.keys(node.dependencies) // Get the node dependencies
      )
      .flatMap(
        (dep) => allNodeNameMaps.get(dep) ?? [] // get all possible implementations of that depenency
      );

    // Upstream is the set of all possible dependencies that are
    // not nodes produced by the function
    // We get this by subtracing all possible dependencies from
    // the set of all nodes produced by the function
    const upstream = new Map(
      allPossibleDependencies
        .filter((node) => !allNodesProducedByFunction.has(node.name))
        .map((node) => [node.name, node])
    );

    // intermeidate is the set of all possible dependencies that *are*
    // produced by the function
    // This is just the inverse of upstream with respect to all possible dependencies
    const intermediate = new Map(
      allPossibleDependencies
        .filter((node) => allNodesProducedByFunction.has(node.name))
        .map((node) => [node.name, node])
    );

    // sinks are all the nodes produced by that function that
    // are not dependenced on by something else in that function
    // Thus we just take all the nodes produced by the function
    // And subtract out any intermeidate nodes
    const sinks = Array.from(allNodesProducedByFunction.values()).filter(
      (node) => !intermediate.has(node.name)
    );
    return {
      upstream: Array.from(upstream.values()),
      sinks: sinks,
      intermediate: Array.from(intermediate.values()),
    };
  };

  getFunctionCreatingNode = (node: HamiltonNode) => {
    for (const fn of this.functions) {
      if (nodeBelongsToFunction(fn, node)) {
        return fn;
      }
    }
    return null;
  };
  /**
   * Gets all downstream nodes of a node.
   * Does a breadth first search with a queue.
   * @param node Node to get downstream nodes of
   * @param includeSelf Whether or not to include self in the results
   */

  getAllDownstream = (
    node: HamiltonNode,
    includeSelf: boolean
  ): HamiltonNode[] => {
    const allNodeImplementations = groupMap(this.nodes);
    const reverseMap = new Map<string, Set<string>>(); // Map of nodes => nodes that depend on them
    const downstream = new Set<string>([node.name]);
    for (const n of this.nodes) {
      for (const dependency of Object.keys(n.dependencies)) {
        const depSet = reverseMap.get(dependency) || new Set<string>();
        depSet.add(n.name);
        reverseMap.set(dependency, depSet);
      }
    }
    const queue = [node];
    while (queue.length > 0) {
      const currentNode = queue.pop();
      if (currentNode === undefined) {
        throw Error("this shouldn't happen");
      }
      const dependencies =
        reverseMap.get(currentNode.name) || new Set<string>();
      for (const dependency of Array.from(dependencies)) {
        if (!downstream.has(dependency)) {
          downstream.add(dependency);
          const possibleImplementations =
            allNodeImplementations.get(dependency) || [];
          possibleImplementations.forEach((n) => {
            queue.push(n);
          });
        }
      }
    }
    const filterFunc = includeSelf
      ? () => true
      : (n: string) => n !== node.name;

    return Array.from(downstream.values())
      .filter(filterFunc)
      .map((name) => allNodeImplementations.get(name) || [])
      .flat();
  };

  getAllUpstream = (
    node: HamiltonNode,
    includeSelf: boolean
  ): HamiltonNode[] => {
    // Get a map of node names to possible implementations
    const allNodeImplementations = groupMap(this.nodes);
    const upstream = new Set<string>([node.name]);
    const queue = [node];
    while (queue.length > 0) {
      const currentNode = queue.pop();
      if (currentNode === undefined) {
        throw Error("this shouldn't happen");
      }
      for (const dependency of Object.keys(currentNode.dependencies)) {
        if (!upstream.has(dependency)) {
          const possibleUpstreamNodes =
            allNodeImplementations.get(dependency) ?? [];
          possibleUpstreamNodes.forEach((n) => {
            queue.push(n);
          });
          upstream.add(dependency);
        }
      }
    }
    const filterFunc = includeSelf ? () => true : (n: string) => n != node.name;
    return (
      Array.from(upstream)
        .filter(filterFunc)
        // Get all possible implementations of the node
        // If its not here (probably shouldn't happen) we just append an empty list
        .flatMap((nodeName) => allNodeImplementations.get(nodeName) ?? [])
    );
  };
}

export const getSubdagSinks = (nodes: HamiltonNode[]) => {
  /**
   * Given a subdag, return the nodes that are sinks
   * That is, the nodes that are not consumed by any other node
   */
  const nodeConsumers = new Map<string, string[]>();
  for (const node of nodes) {
    for (const dependency of Object.values(node.dependencies)) {
      if (nodeConsumers.has(dependency.name)) {
        nodeConsumers.get(dependency.name)?.push(node.name);
      } else {
        nodeConsumers.set(dependency.name, [node.name]);
      }
    }
  }
  const sinks = [];
  for (const node of nodes) {
    if (!nodeConsumers.has(node.name)) {
      sinks.push(node);
    }
  }
  return sinks;
};

export const createLogicalDAG = (dagBase: LogicalDAGBase) => {
  return new LogicalDAGImpl(dagBase);
};

export const getFunctionIdentifier = (fn: HamiltonFunction) => {
  return [...fn.module, fn.name].join(".");
};
