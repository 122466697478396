import React, { FC, useState } from "react";

import Highlight, { defaultProps } from "prism-react-renderer";
import {
  HamiltonFunction,
  HamiltonNode,
  Project,
} from "../../../state/api/backendApiRaw";
import { MdFunctions, MdOutlineExpand } from "react-icons/md";
import { TbLetterI, TbLetterO } from "react-icons/tb";

import { AiOutlineFileSearch, AiOutlineFunction } from "react-icons/ai";

import dracula from "prism-react-renderer/themes/vsLight";

import { getFunctionIdentifier } from "../../../hamilton/dagTypes";
import { BiNetworkChart } from "react-icons/bi";
import { FunctionGraphView } from "./FunctionGraphView";
import { NodeView } from "./CodeViewUtils";

interface FunctionProps {
  fn: HamiltonFunction;
  project: Project;
  intermediateNodes: HamiltonNode[];
  upstreamNodes: HamiltonNode[];
  sinkNodes: HamiltonNode[];
  expanded: boolean;
  toggleExpanded: (all: boolean) => void;
}

const getLinkToFunction = (fn: HamiltonFunction, project: Project) => {
  /**
   * Gets the link to the function in the git repo
   * TODO -- move this to the server side. We'll want to have:
   * (1) branch, if applicable
   * (2) commit hash, in case the branch changes
   * (3) generated perma-link to the function
   */
  const projectBranch = "main"; // TODO -- put branch in project
  const functionPath = fn.module.join("/") + ".py"; // TODOO -- put path in the function on the server side
  let baseDAGPath = project.base_dag_path;
  if (baseDAGPath.endsWith(".py")) {
    baseDAGPath = baseDAGPath.slice(0, baseDAGPath.lastIndexOf("/"));
  }
  // TODO -- determine if the -1 is necessary at all or if we're just off by one
  return `https://${
    project.git_repo
  }/blob/${projectBranch}/${baseDAGPath}/${functionPath}#L${fn.lineStart}-L${
    fn.lineEnd - 1
  }`;
};

export const CodeView: React.FC<{
  fnContents: string;
  displayFnExpand?: boolean;
}> = (props) => {
  const [expanded, setExpanded] = useState(true);
  return (
    <div className={`text-sm w-full`}>
      {/* <h1>{fn.name}</h1> */}
      <div className="flex justify-end relative">
        {props.displayFnExpand && (
          <MdOutlineExpand
            className="text-lg hover:scale-125"
            onClick={() => setExpanded(!expanded)}
          />
        )}
      </div>
      {expanded && (
        <Highlight
          {...defaultProps}
          theme={dracula}
          code={props.fnContents}
          language="python"
        >
          {({ className, style, tokens, getLineProps, getTokenProps }) => {
            const styleToRender = {
              ...style,
              backgroundColor: "transparent",
              // "word-break": "break-word",
              "white-space": "pre-wrap",
            };
            className += "";
            return (
              <pre className={className} style={styleToRender}>
                {tokens.map((line, i) => (
                  // eslint-disable-next-line react/jsx-key
                  <div {...getLineProps({ line, key: i })}>
                    {line.map((token, key) => (
                      // eslint-disable-next-line react/jsx-key
                      <span hidden={false} {...getTokenProps({ token, key })} />
                    ))}
                  </div>
                ))}
              </pre>
            );
          }}
        </Highlight>
      )}
    </div>
  );
};

const NodeListView: React.FC<{
  nodes: HamiltonNode[];
  represents: "input" | "output";
}> = ({ nodes, represents }) => {
  return (
    <>
      {nodes.map((node, index) => (
        <NodeView key={index} node={node} type={represents}></NodeView>
      ))}
    </>
  );
};

const NodeInputOutputView: React.FC<{
  consumes: HamiltonNode[];
  produces: HamiltonNode[];
}> = ({ consumes, produces }) => {
  return (
    <div className="flex flex-col gap-1 py-2 cursor-default">
      <div className="flex flex-row flex-wrap gap-1 pr-1">
        <TbLetterI className="text-2xl pr-1 hover:scale-125 hover:cursor-pointer" />
        <NodeListView nodes={consumes} represents="input" />
      </div>
      <div className="flex flex-row flex-wrap gap-1 pr-1">
        <TbLetterO className="text-2xl hover:scale-125 hover:cursor-pointer" />
        <NodeListView nodes={produces} represents="output" />
      </div>
    </div>
  );
};

const FunctionView: FC<FunctionProps> = ({
  fn,
  project,
  intermediateNodes,
  sinkNodes,
  upstreamNodes,
  expanded,
  toggleExpanded,
}) => {
  /**
   * Basic function view -- allows for linking out, visualizing the DAG, etc...
   */
  // Probably don't need two but I'll mess with it
  const DisplayIcon = expanded ? MdOutlineExpand : MdOutlineExpand;
  const CodeLinkIcon = AiOutlineFileSearch;
  const GraphViewIcon = BiNetworkChart;
  const functionLink = getLinkToFunction(fn, project);

  const handleExpand = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (e.altKey) {
      toggleExpanded(true);
    } else {
      toggleExpanded(false);
    }
  };
  type ViewState = "graph" | "nodes" | "hidden";
  const [miniGraphView, setMiniGraphView] = React.useState<ViewState>("nodes");
  const toggleMiniGraphView = () => {
    const views: ViewState[] = ["nodes", "graph", "hidden"];
    const indexView = views.indexOf(miniGraphView);
    const nextView = views[(indexView + 1) % views.length];
    setMiniGraphView(nextView);
  };

  return (
    <div
      className={
        "flex flex-col gap-2 px-2 rounded-md bg-white shadow border-gray-200 border"
      }
    >
      <div>
        <div
          className={`border-b border-gray-100 py-2 flex flex-row justify-between items-center relative top-0 z-0`}
        >
          <h3 className="font-medium leading-6 text-gray-900">{fn.name}</h3>
          <div className="flex flex-row text-gray-500 gap-2 text-lg z-0">
            <a href={functionLink} target="_blank" rel="noreferrer">
              <CodeLinkIcon className="hover:scale-125 hover:cursor-pointer" />
            </a>
            <DisplayIcon
              onClick={handleExpand}
              className="hover:scale-125 hover:cursor-pointer"
            />
            <GraphViewIcon
              className="hover:scale-125 hover:cursor-pointer"
              onClick={toggleMiniGraphView}
            />
          </div>
        </div>
      </div>
      {expanded && (
        <>
          <CodeView fnContents={fn.contents} />
        </>
      )}

      <div
        className={`w-full ${
          miniGraphView != "hidden" && "border-t"
        } border-gray-200`}
      >
        {miniGraphView === "graph" && (
          <FunctionGraphView
            upstreamNodes={upstreamNodes}
            sinkNodes={sinkNodes}
            intermediateNodes={intermediateNodes}
          />
        )}
        {miniGraphView == "nodes" && (
          <NodeInputOutputView
            consumes={[...upstreamNodes]}
            produces={[...sinkNodes]}
          />
        )}
      </div>
    </div>
  );
};

export default FunctionView;
