/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useEffect, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";

import { AiOutlineProject } from "react-icons/ai";
import { navigation as baseNavigation } from "./nav";
import { BiExit } from "react-icons/bi";
import { useAppSelector } from "../../state/hooks";
import { selectUserHasChosenProject } from "../../state/projectSlice";
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";

type ContextType = { project: Project; dag: LogicalDAG; branch: string };

export const useData = () => {
  [0 / 156];
  return useOutletContext<ContextType>();
};

import { useLogoutFunction } from "@propelauth/react";
import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useProjectGlobals } from "../../state/api/api";
import { Loading } from "../common/Loading";
import {
  createLogicalDAG,
  LogicalDAG,
  LogicalDAGBase,
} from "../../hamilton/dagTypes";
import { RxHamburgerMenu } from "react-icons/rx";
import { Project } from "../../state/api/backendApiRaw";
import { SearchBar } from "./search";
import { NavBreadCrumb } from "./NavBreadCrumb";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export const Dashboard: React.FC = () => {
  const userNavigation = [
    {
      name: "Sign out",
      action: () => logoutFn(true),
      Icon: BiExit,
    },
    {
      name: "Projects",
      action: () => navigate("/projects"),
      Icon: AiOutlineProject,
    },
  ];
  const hasChosenProject = useAppSelector(selectUserHasChosenProject);
  const navigate = useNavigate();
  const logoutFn = useLogoutFunction();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [searchBarOpen, setSearchBarOpen] = useState(false);

  const { dag, project, branch } = useProjectGlobals();
  const whereAmI = useLocation();

  useEffect(() => {
    if (!hasChosenProject) {
      navigate("/projects");
    }
    return;
  }, [hasChosenProject]);

  if (project.isError) {
    return <div>Error loading the project...</div>;
  } else if (project.isLoading || project.isFetching) {
    return <Loading />;
  } else if (project.isUninitialized) {
    return <div>Uninitialized...</div>;
  }

  const getElement = () => {
    const loading =
      project.isLoading ||
      project.isFetching ||
      dag.isLoading ||
      dag.isFetching;
    if (loading) {
      return <Loading />;
    }
    const dagData = createLogicalDAG(dag.data as LogicalDAGBase);
    return (
      <Outlet
        context={{ dag: dagData, project: project.data, branch: branch }}
      />
    );
  };
  const specificNav = [
    {
      name: "Search",
      href: "#",
      action: () => {
        setSearchBarOpen(true);
      },
      icon: MagnifyingGlassIcon,
      current: false,
    },
  ];

  const navigation = [
    ...baseNavigation.map((nav) => {
      return {
        ...nav,
        action: () => {
          navigate(nav.href);
        },
      };
    }),
    ...specificNav,
  ];

  return (
    <>
      <SearchBar open={searchBarOpen} setOpen={setSearchBarOpen} />
      <div className="w-full bg-gray-50 min-h-screen" id="foo">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4">
                    <img
                      className="block h-8 w-auto lg:hidden"
                      src={`${process.env.PUBLIC_URL}/logo.png`}
                      alt="Dagworks"
                    />
                  </div>
                  <div className="mt-5 h-0 flex-1 overflow-y-auto">
                    <nav className="space-y-1 px-2">
                      <div
                        className={classNames(
                          "text-gray-900 hover:bg-gray-50 hover:text-gray-900",
                          "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                        )}
                      >
                        test
                        {/* {project.data ? project.data.project_name : ""} */}
                      </div>
                      {navigation.map((item) => {
                        return (
                          <div
                            key={item.name}
                            onClick={item.action}
                            className={classNames(
                              whereAmI.pathname.includes(item.href)
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-900 hover:bg-gray-50 hover:text-gray-900",
                              "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                            )}
                          >
                            <item.icon
                              className={classNames(
                                whereAmI.pathname.includes(item.href)
                                  ? "text-gray-500"
                                  : "text-gray-400 group-hover:text-gray-500",
                                "mr-4 flex-shrink-0 h-6 w-6"
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </div>
                        );
                      })}
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-grow flex-col overflow-y-auto border-r border-gray-200 bg-white pt-5">
            <div className="flex flex-shrink-0 items-center px-4">
              <img
                className="block h-8 w-auto"
                src={`${process.env.PUBLIC_URL}/logo_with_text.svg`}
                alt="Dagworks"
              />
            </div>
            <div className="mt-5 flex flex-grow flex-col">
              <nav className="flex-1 space-y-1 px-2 pb-4">
                {navigation.map((item) => (
                  <div
                    key={item.name}
                    onClick={item.action}
                    className={classNames(
                      whereAmI.pathname.includes(item.href)
                        ? "bg-gray-100 text-gray-900"
                        : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                      "group flex items-center px-2 py-2 text-md font-medium rounded-md"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        whereAmI.pathname.includes(item.href)
                          ? "text-gray-900"
                          : "text-gray-400 group-hover:text-gray-500",
                        "mr-3 flex-shrink-0 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </div>
                ))}
              </nav>
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col md:pl-64 w-full bg-gray-50">
          <div className="sticky top-0 z-50 flex h-16 flex-shrink-0">
            <div className="flex flex-1 justify-between px-6 text-gray-600 items-center bg-gray-50">
              <NavBreadCrumb project={project.data} />
              <div className="ml-4 flex items-center md:ml-6">
                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span className="sr-only">Open user menu</span>
                      <RxHamburgerMenu className="text-2xl" />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              onClick={() => item.action}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main className="flex-1 h-full w-full">
            <div className="py-0 h-full w-full">
              <div className="mx-auto h-full w-full py-4">
                {/* Replace with your content */}
                {getElement()}
                {/* /End replace */}
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
