import ReactDOM from "react-dom/client";
import "./index.css";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import { persistor, store } from "./state/store";
import { Provider } from "react-redux";
// This is a weird bug: https://github.com/rt2zz/redux-persist/issues/1166
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { PersistGate } from "redux-persist/integration/react";
import { RequiredAuthProvider, RedirectToLogin } from "@propelauth/react";
import { Loading } from "./components/common/Loading";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <RequiredAuthProvider
    authUrl={process.env.REACT_APP_AUTH_URL as string}
    displayWhileLoading={<Loading />}
    displayIfLoggedOut={<RedirectToLogin />}
  >
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </RequiredAuthProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
