import React from "react";
import { DagConfig, Project } from "../../../../state/api/backendApiRaw";
import { DropdownSelector } from "../../../common/Dropdown";
import { HelpTooltip, LabelWithHelpTooltip } from "../../../common/HelpTooltip";

const Configuration: React.FC<{ project: Project }> = (props) => {
  const configs = props.project.configs;
  const [currentConfig, setCurrentConfig] = React.useState<{
    name: string;
    value: DagConfig;
  } | null>(null);
  const displayConfig = (value: any) => {
    if (typeof value === "string") {
      return value;
    }
    console.log(value, typeof value);
    return JSON.stringify(value);
  };
  console.log(currentConfig);
  return (
    <>
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Configuration
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Set up the basics of your execution, including name/description and
            configurations we need to build your DAG. Include any information
            needed for your DAG at compile-time. E.G. the location of input
            tables, random seeds, etc...
          </p>
        </div>

        <div className="mt-5 space-y-6 md:col-span-2 md:mt-0">
          <div className="w-96">
            <DropdownSelector
              choices={configs.map((config) => {
                return { name: config.name, value: config.config };
              })}
              currentChoice={currentConfig}
              setCurrentChoice={setCurrentConfig}
              title={"Select DAG Config"}
              description={
                "This is the configuration used to run your DAG. You can select it from the available configs you registered in the project."
              }
            />
          </div>
          <div className="grid grid-cols-3 gap-6">
            <div className="flex flex-col">
              {currentConfig && (
                <LabelWithHelpTooltip
                  label="Values"
                  description="Values present in your selected config."
                />
              )}
              {[...Object.entries(currentConfig?.value || {})]
                .sort()
                .map(([key, value]) => {
                  console.log(key, value);
                  return (
                    <div
                      className="mt-1 flex rounded-md shadow-sm gap-2"
                      key={key}
                    >
                      <input
                        value={key}
                        className="inline-flex items-center rounded-md border w-96
                 border-gray-300 px-3 text-sm focus:border-dwdarkblue focus: ring-dwdarkblue"
                      ></input>
                      <input
                        value={displayConfig(value)}
                        type="text"
                        name="company-website"
                        id="company-website"
                        className="block w-96 rounded-md
                   border-gray-300 focus:border-dwdarkblue
                    focus:ring-dwdarkblue sm:text-sm"
                        placeholder=""
                      />
                    </div>
                  );
                })}
            </div>
          </div>
          <div>
            <LabelWithHelpTooltip
              label="Name"
              description="Name for your materialization config"
            />
            <div className="mt-1">
              <input
                type="text"
                defaultValue=""
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-dwdarkblue focus:ring-dwdarkblue disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 sm:text-sm"
                placeholder=""
              />
            </div>
          </div>
          <div>
            <LabelWithHelpTooltip
              label="Name"
              description="Description of your materialization config"
            />
            <div className="mt-1">
              <textarea
                id="about"
                name="about"
                rows={3}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-dwdarkblue focus:ring-dwdarkblue sm:text-sm"
                placeholder=""
                defaultValue={""}
              />
            </div>
            <p className="mt-2 text-sm text-gray-500"></p>
          </div>
        </div>
      </div>
      <div className="flex justify-end"></div>
    </>
  );
};

export default Configuration;
