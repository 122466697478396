import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

/**
 * Basic project state -- we store a
 * project that has enough information to
 * query for git code/whatnot
 * TBD
 */
interface BranchState {
  currentProjectBranch: string | undefined;
}

const initialState: BranchState = {
  currentProjectBranch: undefined,
};

export const branchSlice = createSlice({
  initialState,
  name: "branch",
  reducers: {
    setBranch: (state: BranchState, action: PayloadAction<string>) => {
      state.currentProjectBranch = action.payload;
    },
    unsetBranch: (state: BranchState) => {
      state.currentProjectBranch = undefined;
    },
  },
});

export default branchSlice.reducer;

export const { setBranch, unsetBranch } = branchSlice.actions;

export const selectCurrentBranch = (state: RootState) =>
  state.branch.currentProjectBranch;

export const selectUserHasChosenBranch = (state: RootState) =>
  state.branch.currentProjectBranch !== undefined;
