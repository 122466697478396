import React, { useState } from "react";
import { filterUnique, LogicalDAG } from "../../../hamilton/dagTypes";
import { HamiltonNode } from "../../../state/api/backendApiRaw";
import { CodeView } from "../Code/Function";
import { HiChevronRight, HiChevronLeft } from "react-icons/hi";
import { NodeView } from "../Code/CodeViewUtils";
import { MdOutlineExpand } from "react-icons/md";
import { parsePythonType } from "../../../utils";
import { GroupedHamiltonNode } from "./Visualize";

type NodeVizConsoleProps = {
  node: GroupedHamiltonNode;
  dag: LogicalDAG;
};
const NodeVizConsole: React.FC<NodeVizConsoleProps> = ({
  node: groupedNode,
  dag,
}) => {
  // TODO -- allow for diffing sets of nodes...
  // This currently just uses the first node...
  const node = groupedNode.nodes[0];
  const fn = dag.getFunctionCreatingNode(node);
  const { upstream, downstream } = dag.getNeighbors(node);
  const [expanded, setExpanded] = useState(true);
  const DisplayIcon = expanded ? MdOutlineExpand : MdOutlineExpand;
  return (
    // bg-white p-3 rounded-md border-gray-300 border shadow flex flex-col gap-2
    <div className="flex flex-col gap-2 rounded-md bg-white shadow border-gray-300 border p-3 align-baseline">
      <h2
        className={`font-semibold ${
          expanded && "border-b border-gray-200 pb-1.5"
        }`}
      >
        <div className="flex flex-row justify-between">
          <span>{node.name}</span>
          <DisplayIcon
            className="hover:scale-125 hover:cursor-pointer text-lg"
            onClick={() => setExpanded(!expanded)}
          />
        </div>
      </h2>
      {expanded && (
        <>
          <div className="border-b border-gray-200 py-1.5 flex flex-col gap-2">
            <p className="text-gray-500">{parsePythonType(node.returnType)}</p>
            <pre className="whitespace-pre-wrap break-words text-gray-500">
              {node.documentation}
            </pre>
          </div>
          {fn ? (
            <CodeView fnContents={fn.contents} displayFnExpand></CodeView>
          ) : (
            <span className="italic">External input...</span>
          )}
          <div className="border-gray-200 flex flex-col flex-wrap gap-3">
            {upstream.length > 0 && (
              <div className="flex flex-row gap-2">
                <>
                  <HiChevronRight className="text-2xl" />
                  {filterUnique(upstream).map((n, index) => (
                    <NodeView key={index} node={n} type="input" />
                  ))}
                </>
              </div>
            )}
            {downstream.length > 0 && (
              <div className="flex flex-row flex-wrap gap-2">
                <>
                  <HiChevronLeft className="text-2xl" />
                  {filterUnique(downstream).map((n, index) => (
                    <NodeView node={n} key={index} type="output" />
                  ))}
                </>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default NodeVizConsole;
