import { IconType } from "react-icons";
import {
  AiOutlineDatabase,
  AiOutlineNumber,
  AiOutlineQuestion,
  AiOutlineStock,
} from "react-icons/ai";

import { BiBracket } from "react-icons/bi";
import { BsBraces, BsCardChecklist } from "react-icons/bs";
import { FaChartLine } from "react-icons/fa";
import { GoQuote } from "react-icons/go";
import { HamiltonNode, PythonType } from "./state/api/backendApiRaw";
import { SiScikitlearn } from "react-icons/si";
import { RiParenthesesFill } from "react-icons/ri";
import { RxComponentBoolean } from "react-icons/rx";

export const parsePythonType = (pythonType: PythonType) => {
  return pythonType.typeName.replace("<class '", "").replace("'>", "");
};

export const getPythonTypeIcon = (pythonType: PythonType): IconType => {
  const parsedTypeName = parsePythonType(pythonType);
  if (parsedTypeName === "pandas.core.series.Series") {
    return AiOutlineStock;
  } else if (parsedTypeName === "pandas.core.frame.DataFrame") {
    return AiOutlineDatabase;
  } else if (
    parsedTypeName.startsWith("numpy.float") ||
    parsedTypeName.startsWith("numpy.int")
  ) {
    return AiOutlineNumber;
  } else if (parsedTypeName === "hamilton.data_quality.base.ValidationResult") {
    return BsCardChecklist;
  } else if (parsedTypeName === "str") {
    return GoQuote;
  } else if (parsedTypeName.startsWith("numpy.ndarray")) {
    return FaChartLine;
  } else if (parsedTypeName === "int" || parsedTypeName === "float") {
    return AiOutlineNumber;
  } else if (parsedTypeName === "bool") {
    return RxComponentBoolean;
  } else if (parsedTypeName.toLowerCase().startsWith("tuple")) {
    return RiParenthesesFill;
  } else if (
    parsedTypeName.startsWith("list") ||
    parsedTypeName.startsWith("set") ||
    parsedTypeName.startsWith("typing.List")
  ) {
    return BiBracket;
  } else if (
    parsedTypeName.toLowerCase().startsWith("dict") ||
    parsedTypeName.startsWith("typing.Dict")
  ) {
    return BsBraces;
  } else if (parsedTypeName.startsWith("sklearn")) {
    return SiScikitlearn;
  } else {
    return AiOutlineQuestion;
  }
};

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

/**
 * Uniqifies then combines
 * Doesn't preserve order
 * @param l List of lists to combine
 */
export const uniqueCombine = <T>(...l: T[]) => {
  return Array.from(new Set(l.flatMap((_) => _)));
};

export const intersectArrays = <T>(l1: T[], l2: T[]) => {
  return l1.filter((x) => l2.includes(x));
};
