import { AiFillCode, AiOutlineProject } from "react-icons/ai";
import { BiHelpCircle, BiRun } from "react-icons/bi";
import { FiDatabase } from "react-icons/fi";
import { GoBook } from "react-icons/go";
import { IoGitNetworkSharp } from "react-icons/io5";
import { TbReportAnalytics } from "react-icons/tb";

export const navigation = [
  {
    name: "Code",
    href: "/dashboard/code",
    icon: AiFillCode,
    current: true,
  },
  {
    name: "Visualize",
    href: "/dashboard/visualize",
    icon: IoGitNetworkSharp,
  },
  {
    name: "Catalogue",
    href: "/dashboard/catalogue",
    icon: GoBook,
  },
  {
    name: "Runs",
    href: "/dashboard/runs",
    icon: BiRun,
  },
  {
    name: "Materialize",
    href: "/dashboard/materialize",
    icon: FiDatabase,
  },
  {
    name: "Report",
    href: "/dashboard/report",
    icon: TbReportAnalytics,
  },
  {
    name: "Projects",
    href: "/projects",
    icon: AiOutlineProject,
  },
  {
    name: "Help",
    href: "#",
    icon: BiHelpCircle,
  },
];
