import { FC, useState } from "react";
import { DagRun } from "../../../state/api/backendApiRaw";
import { Link } from "react-router-dom";
import { RunStatus } from "./Run/Status";
import { FiExternalLink } from "react-icons/fi";

const TableRow: React.FC<{ run: DagRun }> = ({ run }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <tr className="hover:bg-slate-100 h-12">
      {cols.map((col, index) => {
        const ToRender = col.render;
        return (
          <td key={index} className="py-2 px-3 text-sm max-w-sm text-gray-500">
            {<ToRender run={run} expanded={expanded} status={run.status} />}
          </td>
        );
      })}
    </tr>
  );
};
type RenderProps = {
  run: DagRun;
  status: string;
  expanded: boolean;
};
const cols = [
  {
    displayName: "Run ID",
    render: (props: RenderProps) => (
      // This is a little awkward -- we need to think through the best way to model this (UUIDs? Or just IDs?)
      <Link to={`/dashboard/runs/${props.run.id}`}>{props.run.run_id}</Link>
    ),
  },
  {
    displayName: "Duration",
    render: (props: RenderProps) => {
      const startMillis = new Date(props.run.start_time).getTime();
      const endMillis = new Date(props.run.end_time).getTime();
      const durationSeconds = (endMillis - startMillis) / 1000;
      return (
        <span className="font-semibold">{durationSeconds.toFixed(3)}s</span>
      );
    },
  },
  {
    displayName: "Start Time",
    render: (props: RenderProps) => {
      // TODO -- consolidate logic for this with getFunctionIdentifier in dagtypes
      // Moving too fast to care right now
      return <span>{new Date(props.run.start_time).toLocaleString()}</span>;
    },
  },
  // TODO -- include time zone support
  // {
  //   displayName: "End Time",
  //   render: (props: RenderProps) => {
  //     return <span> {new Date(props.run.end_time).toLocaleString()}</span>;
  //   },
  // },
  {
    displayName: "Project",
    render: (props: RenderProps) => {
      return <span> {props.run.project_id}</span>;
    },
  },
  {
    displayName: "Status",
    render: (props: RenderProps) => {
      return <RunStatus status={props.run.status} />;
    },
  },
  {
    displayName: "",
    render: (props: RenderProps) => {
      return (
        <FiExternalLink className="text-xl hover:scale-125 cursor-pointer" />
      );
    },
  },
];

export const RunsTable: FC<{ runs: DagRun[] }> = (props) => {
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-3 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr className="">
                  {cols.map((col, index) => (
                    <th
                      key={index}
                      scope="col"
                      className="py-3.5 pl-3 pr-3 text-left text-sm font-semibold text-gray-900"
                    >
                      {col.displayName}
                    </th>
                  ))}
                  {<th className="w-6"></th>}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {[...props.runs]
                  .sort(
                    (runA, runB) =>
                      new Date(runB.start_time).getTime() -
                      new Date(runA.start_time).getTime()
                  )
                  .map((run, index) => {
                    return <TableRow run={run} key={index} />;
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
