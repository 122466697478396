import { skipToken } from "@reduxjs/toolkit/dist/query";
import { selectCurrentBranch } from "../branchSlice";
import { useAppSelector } from "../hooks";
import { selectProjectID } from "../projectSlice";
import {
  useGitserverApiApiGetProjectsQuery,
  useGitserverApiApiGetProjectQuery,
  useGitserverApiApiGetDagFromProjectQuery,
  useGitserverApiApiGetExecutionsByProjectQuery,
  useGitserverApiApiGetRunLogQuery,
  useGitserverApiApiGetDagFromProjectWithConfigQuery,
} from "./backendApiRaw";

/**
 * This is a hook that will return the project
 * given a project ID
 * TODO -- get this to use a projectID and find the right way to
 * use the skiptoken -- typescript doesn't allow it...
 */

export const useProject = useGitserverApiApiGetProjectQuery;
export const useProjects = useGitserverApiApiGetProjectsQuery;
export const useDAGFromProject = useGitserverApiApiGetDagFromProjectQuery;
export const useDAGFromProjectWithConfig =
  useGitserverApiApiGetDagFromProjectWithConfigQuery;
export const useRunsFromProject = useGitserverApiApiGetExecutionsByProjectQuery;
export const useRun = useGitserverApiApiGetRunLogQuery;

export const useProjectGlobals = () => {
  const projectId = useAppSelector(selectProjectID);
  let branch = useAppSelector(selectCurrentBranch);
  const project = useProject(
    projectId !== undefined ? { projectId: projectId } : skipToken
  );
  if (branch === undefined) {
    branch = project.data?.default_branch;
  }

  const dag = useDAGFromProject(
    projectId !== undefined
      ? { branch: branch, projectId: projectId }
      : skipToken
  );
  return { dag, project, projectId, branch };
};
