import { FC, ReactNode, useMemo, useState } from "react";
import { LogicalDAG } from "../../../hamilton/dagTypes";
import { HamiltonNode, PythonType } from "../../../state/api/backendApiRaw";
import Fuse from "fuse.js";
import { HashLink } from "react-router-hash-link";
import { getPythonTypeIcon, parsePythonType } from "../../../utils";
import { HiChevronDown, HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { MdOutlineExpand } from "react-icons/md";

const displayTagValue = (value: any) => {
  if (typeof value === "string") {
    return value;
  }
  console.log(value, typeof value);
  return JSON.stringify(value);
};
const TagDisplay: React.FC<{ tags: object; expanded: boolean }> = (props) => {
  const tagsToMap = props.expanded
    ? Object.entries(props.tags)
    : Object.entries(props.tags).slice(0, 1);
  return (
    <div className={`w-96 flex flex-col truncate`}>
      {tagsToMap.map(([key, value]) => {
        return (
          <div key={key} className="flex flex-row gap-1 items-baseline">
            <span className="font-semibold">{key}</span>
            {displayTagValue(value)}
          </div>
        );
      })}
    </div>
  );
};
const DocumentationDisplay: React.FC<{
  documentation: string | undefined;
  expanded: boolean;
}> = (props) => {
  return (
    <pre
      className={`w-84 ${
        props.expanded ? "break-word  whitespace-pre-wrap" : "truncate"
      }  text-gray-500`}
    >
      {props.documentation}
    </pre>
  );
};
const ToolTip: React.FC<{ children: ReactNode; tooltip?: string }> = (
  props
) => {
  return (
    <div className="group relative inline-block">
      {props.children}
      <span
        className="invisible group-hover:visible opacity-0 group-hover:opacity-100 transition
           bg-dwred text-white p-1 rounded absolute top-full mt-2 whitespace-nowrap"
      >
        {props.tooltip}
      </span>
    </div>
  );
};
const FeatureNameDisplay: React.FC<{ name: string; type: PythonType }> = (
  props
) => {
  const Icon = getPythonTypeIcon(props.type);
  return (
    <div className="flex flex-row items-center gap-2">
      <ToolTip tooltip={parsePythonType(props.type)}>
        <Icon className="text-lg hover:scale-125"></Icon>
      </ToolTip>
      <span className="font-semibold">{props.name}</span>
    </div>
  );
};

const TableRow: React.FC<{ node: HamiltonNode }> = ({ node }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <tr className="hover:bg-slate-100 h-12">
      {cols.map((col, index) => {
        const ToRender = col.render;
        return (
          <td key={index} className="py-2 px-3 text-sm max-w-sm text-gray-500">
            {<ToRender node={node} expanded={expanded} />}
          </td>
        );
      })}
      <td className="py-2 px-3 max-w-sm text-gray-500 text-xl align-top pt-4 cursor-pointer">
        <MdOutlineExpand onClick={() => setExpanded(!expanded)} />
      </td>
    </tr>
  );
};
type RenderProps = {
  node: HamiltonNode;
  expanded: boolean;
};
const cols = [
  {
    displayName: "Feature",
    render: (props: RenderProps) => (
      <FeatureNameDisplay name={props.node.name} type={props.node.returnType} />
    ),
  },
  {
    displayName: "Function",
    render: (props: RenderProps) => {
      // TODO -- consolidate logic for this with getFunctionIdentifier in dagtypes
      // Moving too fast to care right now
      const [expand, setExpand] = useState(true);
      const fnLocation = props.node.functionIdentifier.join("_");
      return (
        <HashLink
          className="text-dwlightblue/90"
          to={`/dashboard/code/#${fnLocation}`}
        >
          {fnLocation}
        </HashLink>
      );
    },
  },
  // {
  //   displayName: "Type",
  //   render: (node: HamiltonNode) => {
  //     return <span>{parsePythonType(node.returnType)}</span>;
  //   },
  // },
  {
    displayName: "Documentation",
    render: (props: RenderProps) => {
      return (
        <DocumentationDisplay
          documentation={props.node.documentation}
          expanded={props.expanded}
        />
      );
    },
  },
  {
    displayName: "Tags",
    render: (props: RenderProps) => {
      return (
        <TagDisplay
          expanded={props.expanded}
          tags={props.node.tags}
        ></TagDisplay>
      );
    },
  },
];

interface FeatureSearchBoxProps {
  setSearch: (term: string) => void;
  term: string;
}

const FeatureSearchBox: React.FC<FeatureSearchBoxProps> = ({
  setSearch,
  term,
}) => {
  return (
    <div>
      <input
        value={term}
        onChange={(e) => setSearch(e.target.value)}
        className="text-lg block w-full rounded-md border-dwlightblue/30 border shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm py-2 px-2"
        placeholder="Search for nodes, functions, etc..."
      />
    </div>
  );
};

export const SearchCatalogue: FC<{ dag: LogicalDAG }> = (props) => {
  const fuse = useMemo(
    () =>
      new Fuse(props.dag.nodes, {
        keys: ["name", "functionIdentifier"],
        threshold: 0.45,
      }),
    [props.dag]
  );
  const [searchTerm, setSearchTerm] = useState("");

  const shouldDisplay = (node: { item: HamiltonNode }) => {
    // return true;
    //eslint-disable-next-line no-prototype-builtins
    return !node.item.tags.hasOwnProperty("hamilton.decorators.non_final"); // Really it should check the value...
  };
  const getSearchResult = (term: string) => {
    if (searchTerm == "") {
      // This is a little messy cause it won't include all the search result fields necessarily...
      return props.dag.nodes
        .map((node) => {
          return { item: node };
        })
        .sort((n1, n2) => {
          return n1.item.name.localeCompare(n2.item.name);
        });
    }
    return fuse.search(term);
  };

  const searchResults = getSearchResult(searchTerm).filter(shouldDisplay); // TODO -- toggle it?
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <FeatureSearchBox
            setSearch={setSearchTerm}
            term={searchTerm}
          ></FeatureSearchBox>
        </div>
      </div>
      <div className="mt-3 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr className="">
                  {cols.map((col, index) => (
                    <th
                      key={index}
                      scope="col"
                      className="py-3.5 pl-3 pr-3 text-left text-sm font-semibold text-gray-900"
                    >
                      {col.displayName}
                    </th>
                  ))}
                  {<th className="w-6"></th>}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {searchResults.map((fuseResult, index) => {
                  const node = fuseResult.item;
                  return <TableRow node={node} key={index} />;
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
