import { createSelector } from "@reduxjs/toolkit";
import { IconType } from "react-icons";
import {
  createLogicalDAG,
  getFunctionIdentifier,
  LogicalDAGBase,
} from "../hamilton/dagTypes";
import { backendApi } from "./api/backendApiRaw";
import { RootState } from "./store";
import { FaProjectDiagram } from "react-icons/fa";
import { AiOutlineFunction, AiOutlineNodeIndex } from "react-icons/ai";
import { navigation } from "../components/dashboard/nav";
import { useProjectGlobals } from "./api/api";

// SearchableCategory is a category of items that can be searched for
export type SearchableCategory = {
  name: string; // Name of the category
  shortcut: string; // Shortcut within search dialogue for the category
  displayAll: boolean;
};

export type SearchableItem = {
  name: string;
  href: string; // TODO -- determine what else we need to render this in the react context
  searchableText: string[];
  category: SearchableCategory;
  icon: IconType; // Icon to display next to the category
};
const DEFAULT_SEARCHABLES = [] as SearchableItem[];

// Currently displayAll is not used, but
// it should be used to determine whether to display all items on default or
// require the user to type something to search for items
// TODO -- use displayAll
const SEARCHABLE_CATEGORIES = {
  project: {
    name: "projects",
    // icon: (item: SearchableItem) => FaProjectDiagram,
    shortcut: ">",
    displayAll: true,
  },
  node: {
    name: "nodes",
    // icon: AiOutlineNodeIndex,
    shortcut: ".",
    displayAll: false,
  },
  fn: {
    name: "functions",
    // icon: AiOutlineFunction,
    shortcut: ":",
    displayAll: false,
  },
  page: {
    name: "navigation",
    // icon: IoNavigateOutline,
    shortcut: "/",
    displayAll: false,
  },
};

const extractDefaultSearchables = (state: RootState) => {
  return navigation.map(({ name, href, icon, current }) => {
    return {
      name,
      href,
      searchableText: [name],
      category: SEARCHABLE_CATEGORIES.page,
      icon: icon,
    };
  });
};

const extractDAGSearchables = (state: RootState) => {
  const out = [] as SearchableItem[];
  const hasSelectedProject = state.project.hasSelectedProject;
  const currentProjectID = state.project.currentProjectID;
  // if (!hasSelectedProject || currentProjectID === undefined) {
  //   // If we haven't yet gotten a project selected, then we return an empty array -- nothing else to search through...
  //   return out;
  // }
  // const dag = backendApi.endpoints.gitserverApiApiGetDagFromProject.select({
  //   projectId: currentProjectID,
  // })(state);
  // if (!dag.isSuccess) {
  //   // If the DAG is not succesfully loaded, we have nothing to search through...
  //   return out;
  // }
  const { dag: rawDAG, project } = useProjectGlobals();
  if (!rawDAG.data || !project.data) {
    return out;
  }
  const dag = createLogicalDAG(rawDAG.data as LogicalDAGBase);
  console.log("dag loaded", dag);
  dag.nodes.map((node) => {
    out.push({
      name: node.name,
      href: "/dashboard/visualize", // TODO -- figure out where to put this -- probably to the containing function? Or the catalog location?
      searchableText: [node.name, node.documentation || ""],
      category: SEARCHABLE_CATEGORIES.node,
      icon: AiOutlineNodeIndex,
    });
  });

  dag.functions.map((fn) => {
    out.push({
      name: fn.name,
      href: `/dashboard/code/#${getFunctionIdentifier(fn).replace(".", "_")}`, // TODO -- figure out where to put this -- probably to the containing function? Or the catalog location?
      searchableText: [fn.name],
      category: SEARCHABLE_CATEGORIES.fn,
      icon: AiOutlineFunction,
    });
  });
  return out;
};

const extractProjectSearchables = (state: RootState): SearchableItem[] => {
  const projects =
    backendApi.endpoints.gitserverApiApiGetProjects.select()(state);
  if (!projects.isSuccess) {
    return [];
  }
  return projects.data.map((project) => {
    return {
      name: project.project_name,
      href: "/projects", // TODO -- figure out where to put this -- probably to the containing function? Or the catalog location?
      searchableText: [project.project_name, project.project_description],
      category: SEARCHABLE_CATEGORIES.project,
      icon: FaProjectDiagram,
    };
  });
};

const extractSearchables = (state: RootState): SearchableItem[] => {
  let out = [...DEFAULT_SEARCHABLES];

  out = [
    ...extractDefaultSearchables(state),
    ...extractDAGSearchables(state),
    ...extractProjectSearchables(state),
  ];
  return out;
  //   state.backendApi.queries
};

type Selector<S> = (state: RootState) => S;
const selectSelf = (state: RootState) => state;
/**
 * Selects all searchables from state
 * Note this is probably not the most efficient (as its just gets the whole state)
 * But its an easy way to get started.
 * We should really be using multiple selectors with reselect
 * @returns a selector that returns all searchable items
 */
export const selectSearchables = (): Selector<SearchableItem[]> =>
  createSelector([selectSelf], (state) => {
    return extractSearchables(state);
  });

export const selectSearchableCategories = (): Selector<SearchableCategory[]> =>
  createSelector([selectSelf], (state) => {
    return Object.values(SEARCHABLE_CATEGORIES);
  });
