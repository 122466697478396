import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useRun } from "../../../../state/api/api";
import { Loading } from "../../../common/Loading";
import { TaskTable } from "./TaskTable";
import WaterfallChart from "./WaterfallChart";

const Run = () => {
  const location = useLocation();
  const [highlightedTask, setHighlightedTask] = useState<string | null>(null);
  const run = useRun({ runId: parseInt(location.pathname.split("/")[3]) });
  if (run.isLoading) {
    return <Loading />;
  }
  if (run.data === undefined) {
    return <div>Run not found</div>;
  }
  return (
    <div className="flex flex-col w-full">
      {/* This really isn't the best way to do this -- we need to fix the layout later */}
      <div className="sticky top-14 z-50 bg-gray-50 px-5">
        <WaterfallChart
          run={run.data}
          highlightedTask={highlightedTask}
          setHighlightedTask={setHighlightedTask}
        />
      </div>
      <div>
        <TaskTable
          tasks={run.data.run_log.task_data.tasks}
          highlightedTask={highlightedTask}
          setHighlightedTask={setHighlightedTask}
        />
      </div>
    </div>
  );
};

export default Run;
