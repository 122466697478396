import { FC, useState } from "react";
import { getFunctionIdentifier, LogicalDAG } from "../../../hamilton/dagTypes";
import { Project } from "../../../state/api/backendApiRaw";
import CodeExplorer from "./CodeExplorer";
import FunctionView from "./Function";
interface DevelopProps {
  project: Project;
  dag: LogicalDAG;
}
export const Code: FC<DevelopProps> = ({ project, dag }) => {
  // An array of booleans, one for each function, indicating whether it's expanded
  const [whichExpanded, setWhichExpanded] = useState(
    dag.functions.map(() => true)
  );
  // A convenience function to toggle the expanded state of an individual function
  const toggleExpanded = (index: number, all: boolean) => {
    // const currentState = whichExpanded[index]
    let newWhichExpanded = [...whichExpanded];
    if (all) {
      newWhichExpanded = whichExpanded.map(() => !whichExpanded[index]);
    } else {
      newWhichExpanded[index] = !newWhichExpanded[index];
    }
    setWhichExpanded(newWhichExpanded);
  };

  return (
    <div className="flex flex-row gap-8 justify-between">
      <div className="w-122">
        <CodeExplorer dag={dag} project={project} />
      </div>
      <div className="rounded-md w-192 max-w-10xl flex-grow scroll-smooth">
        <div role="list" className="space-y-5 !scroll-smooth">
          {dag.functions.map((fn, index) => {
            const { sinks, intermediate, upstream } = dag.getImpliedNodes(fn);
            return (
              <div
                key={index}
                id={getFunctionIdentifier(fn).replace(".", "_")}
                className="scroll-m-16 scroll-smooth"
              >
                <FunctionView
                  key={index}
                  fn={fn}
                  project={project}
                  intermediateNodes={intermediate}
                  sinkNodes={sinks}
                  upstreamNodes={upstream}
                  expanded={whichExpanded[index]}
                  toggleExpanded={(all: boolean) => toggleExpanded(index, all)}
                ></FunctionView>
              </div>
            );
          })}
        </div>
      </div>
      <div className="w-0"></div>
    </div>
  );
};
