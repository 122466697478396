import React from "react";
import { LogicalDAG } from "../../../../hamilton/dagTypes";
import { Project } from "../../../../state/api/backendApiRaw";
import { DropdownSelector } from "../../../common/Dropdown";

type OrchestrationEngine = {
  name: string;
  value: string;
  allowNodeGrouping: boolean;
};

const CICDOptions = [
  { name: "Human Review (launch PR)", value: "manual" },
  { name: "Automatically Update", value: "manual" },
];

const AVAILABLE_GIT_REPOS = [
  { name: "hamilton", value: "github.com/stitchfix/hamilton" },
  { name: "dagworks-utils", value: "github.com/dagworks-inc/dagworks-utils" },
];

const DEFAULT_CICD_OPTION = null;

const Synchronize: React.FC<{ project: Project; dag: LogicalDAG }> = (
  props
) => {
  const [ciCDOption, setCICDOption] = React.useState(DEFAULT_CICD_OPTION);
  const [gitRepoPublishTo, setGitRepoPublishTo] = React.useState<string | null>(
    null
  );
  const [gitBranchPublishTo, setGitBranchPublishTo] = React.useState<
    string | null
  >(null);

  return (
    <>
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Launch
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Integrate your workflow into your production environment.
          </p>
        </div>

        <div className="mt-5 space-y-6 md:col-span-2 md:mt-0">
          <div>
            <DropdownSelector
              choices={CICDOptions}
              setCurrentChoice={setCICDOption}
              currentChoice={ciCDOption}
              title="When to deploy"
            ></DropdownSelector>
          </div>
          {/* TODO -- refactor all of these into common components*/}
          <div>
            <label className="block text-sm font-medium text-gray-700 max-w-96">
              Git Repo (listen to)
            </label>
            <div className="mt-1">
              <input
                readOnly
                type="text"
                value={props.project.git_repo}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-dwdarkblue focus:ring-dwdarkblue disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 sm:text-sm"
                placeholder=""
              />
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 max-w-96">
              Git Branch (listen to)
            </label>
            <div className="mt-1">
              <input
                readOnly
                type="text"
                value={props.project.default_branch} // TODO -- allow selection
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-dwdarkblue focus:ring-dwdarkblue disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 sm:text-sm"
                placeholder=""
              />
            </div>
          </div>

          <div>
            <DropdownSelector
              choices={AVAILABLE_GIT_REPOS}
              setCurrentChoice={setGitRepoPublishTo}
              currentChoice={gitRepoPublishTo}
              title="Git Repo (publish to)"
            ></DropdownSelector>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 max-w-96">
              Git Branch (publish to)
            </label>
            <div className="mt-1">
              <input
                onChange={(e) => setGitBranchPublishTo(e.target.value)}
                type="text"
                value={gitBranchPublishTo || ""}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-dwdarkblue focus:ring-dwdarkblue disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 sm:text-sm"
                placeholder=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end"></div>
    </>
  );
};

export default Synchronize;
