import { HamiltonNode } from "../../../state/api/backendApiRaw";

export type NodeType = "input" | "intermediate" | "output";
const colors = {
  output: "bg-dwlightblue/80",
  input: "bg-dwred/80",
  intermediate: "bg-dwdarkblue/80",
};

export const NodeView: React.FC<{
  node: HamiltonNode;
  type: NodeType;
}> = ({ node, type }) => {
  const color = colors[type];
  return (
    <div className={`rounded-full p-1 px-2  text-white text-sm ${color}`}>
      {node.name}
    </div>
  );
};
