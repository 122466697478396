import dagre from "dagre";
import React, { useEffect } from "react";
import ReactFlow, {
  Controls,
  Handle,
  MiniMap,
  Position,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "reactflow";
import { LogicalDAG } from "../../hamilton/dagTypes";
import { HamiltonNode } from "../../state/api/backendApiRaw";

type NodeGroupingVisualizerProps = {
  dag: LogicalDAG;
  groupingFunction: (node: HamiltonNode) => string;
  removeUserDefined: boolean;
};

type NodeGroup = {
  name: string;
  nodes: HamiltonNode[];
  dependencies: Set<string>;
};

const groupNodes = (
  nodes: HamiltonNode[],
  groupingFunction: (node: HamiltonNode) => string
): NodeGroup[] => {
  const nodeGroups: NodeGroup[] = [];
  const nodeToGroupMapping = new Map<string, string>();
  // First pass so we can determine dependencies by grouping all
  for (const node of nodes) {
    const groupName = groupingFunction(node);
    nodeToGroupMapping.set(node.name, groupName);
  }
  const getDependencies = (node: HamiltonNode): Set<string> => {
    return new Set(
      Object.keys(node.dependencies)
        .map((dep) => nodeToGroupMapping.get(dep) as string)
        .filter((dep) => dep !== (nodeToGroupMapping.get(node.name) as string))
    );
  };
  for (const node of nodes) {
    const groupName = nodeToGroupMapping.get(node.name) as string; // We know we've seen it already...
    // This might be wrong given that multiple nodes have the same name, but its good enough for now
    nodeToGroupMapping.set(node.name, groupName);
    const group = nodeGroups.find((group) => group.name === groupName);
    if (group) {
      group.nodes.push(node);
      group.dependencies = new Set([
        ...Array.from(group.dependencies),
        ...Array.from(getDependencies(node)),
      ]);
    } else {
      nodeGroups.push({
        name: groupName,
        nodes: [node],
        dependencies: getDependencies(node),
      });
    }
  }
  return nodeGroups;
};

type VizNode = {
  id: string;
  position: { x: number; y: number };
  data: NodeGroup;
  type: string;
  targetPosition: Position;
  sourcePosition: Position;
};

type VizEdge = {
  id: string;
  source: string;
  target: string;
};

const convertToNodesAndEdges = (nodes: NodeGroup[]): [VizNode[], VizEdge[]] => {
  const outputNodes = nodes.map((node) => ({
    id: node.name,
    position: { x: 0, y: 0 }, // These are overwritten later
    data: { ...node },
    type: "custom",
    targetPosition: Position.Right,
    sourcePosition: Position.Left,
  }));
  // Hacking in different IDs for edges to make sure everything works
  const outputEdges = nodes.flatMap((node, i) => {
    return Array.from(node.dependencies).flatMap((dependencyName, j) => ({
      id: `${node.name}-${dependencyName}-${i}-${j}`,
      source: dependencyName,
      target: node.name,
    }));
  });
  //   console.log(outputEdges);
  return [outputNodes, outputEdges];
};

const getLayoutedElements = (
  nodes: VizNode[],
  edges: VizEdge[],
  direction = "TB"
) => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  const nodeWidth = 48;
  const nodeHeight = 12;

  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: (nodeWithPosition.x - nodeWidth / 2) * 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };
    return node;
  });

  return { nodes, edges };
};

function CustomNodeComponent({ data }: { data: HamiltonNode }) {
  // const { upstreamNodes, downstreamNodes, node, setNodeToHighlight } =
  //   useContext(SelectedNodeContext);
  // const upstreamHighlighted = upstreamNodes.has(data.name);
  // const downstreamHighlighted = downstreamNodes.has(data.name);
  // const selfHighlighted = node === data.name;
  return (
    <div
      //   onClick={() => setNodeToHighlight(data)}
      className={`px-4 ${"text-white bg-dwdarkblue/80"} py-2 shadow-sm rounded-lg  text-white w-max text-xl`}
    >
      <div className="flex w-max">
        <div className="w-max h-12 flex justify-center items-center ">
          {data.name}
        </div>
      </div>

      <Handle
        type="target"
        position={Position.Left}
        // className="w-16 !bg-teal-500"
      />
      <Handle
        type="source"
        position={Position.Right}
        // className="w-16 !bg-teal-500"
      />
    </div>
  );
}

const nodeTypes = { custom: CustomNodeComponent };

const NodeGroupingVisualizer: React.FC<NodeGroupingVisualizerProps> = (
  props
) => {
  const originalNodes = props.dag.nodes;
  const nodesToUse = originalNodes.filter(
    (node) => node.functionIdentifier.length > 0
  );
  console.log(nodesToUse);
  const nodeGroups = groupNodes(nodesToUse, props.groupingFunction);
  const [initNodes, initEdges] = convertToNodesAndEdges(nodeGroups);
  const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
    initNodes,
    initEdges,
    "LR"
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);

  useEffect(() => {
    setNodes(layoutedNodes);
    setEdges(layoutedEdges);
  }, [props.groupingFunction]);

  //eslint-disable-next-line no-debugger
  return (
    <div className="h-full w-full">
      <ReactFlowProvider>
        <ReactFlow
          nodesDraggable={true}
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          // onNodeMouseLeave={(e) => {
          //   e.stopPropagation();
          //   e.preventDefault();
          //   setTooltip({
          //     node: null,
          //     top: 0,
          //     left: 0,
          //   });
          // }}
          // onNodeMouseEnter={(e, node) => {
          //   e.stopPropagation();
          //   e.preventDefault();
          //   setTooltipProps({
          //     node: node.data,
          //     top: e.clientY,
          //     left: e.clientX,
          //   });
          // }}
          nodeTypes={nodeTypes}
          minZoom={0.2}
          fitView
          // TODO -- donate/buy pro option when we have more revenue...
          proOptions={{ hideAttribution: true }}
        >
          {" "}
          <Controls />
          <MiniMap pannable={true} ariaLabel={null} position="bottom-left" />
        </ReactFlow>
      </ReactFlowProvider>
    </div>
  );
};

export default NodeGroupingVisualizer;
