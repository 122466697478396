import { MaterializationConfig } from "./types";

export const mockMaterializationConfigs: MaterializationConfig[] = [
  {
    id: 1,
    name: "Production (US)",
    description: "Production Execution Environment",
    created: new Date("2023-01-08T11:30:34.000Z"),
    updated: new Date("2023-01-18T12:50:47.000Z"),
    runHistory: [
      {
        runId: "runId1",
        runTime: new Date(),
      },
      {
        runId: "runId2",
        runTime: new Date(),
      },
    ],
  },
  {
    id: 2,
    name: "Staging (US)",
    description: "Staging Execution Environment",
    created: new Date("2023-01-06T01:24:34.000Z"),
    updated: new Date("2023-01-25T08:23:12.000Z"),
    runHistory: [
      {
        runId: "runId1",
        runTime: new Date(),
      },
      {
        runId: "runId2",
        runTime: new Date(),
      },
    ],
  },
  {
    id: 3,
    name: "Production (UK)",
    description: "Staging Execution Environment",
    created: new Date("2023-01-07T01:24:34.000Z"),
    updated: new Date("2023-01-26T08:23:12.000Z"),
    runHistory: [
      {
        runId: "runId1",
        runTime: new Date(),
      },
      {
        runId: "runId2",
        runTime: new Date(),
      },
    ],
  },
  {
    id: 4,
    name: "Staging (UK)",
    description: "Staging Execution Environment",
    created: new Date("2023-01-08T01:24:34.000Z"),
    updated: new Date("2023-01-23T08:23:12.000Z"),
    runHistory: [
      {
        runId: "runId1",
        runTime: new Date(),
      },
      {
        runId: "runId2",
        runTime: new Date(),
      },
    ],
  },
];
