import React, { ReactNode, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import {
  createLogicalDAG,
  LogicalDAGBase,
} from "../../../../hamilton/dagTypes";
import { useProjectGlobals } from "../../../../state/api/api";
import { Loading } from "../../../common/Loading";
import { MaterializationConfig } from "../types";
import Artifacts from "./Artifacts";
import Configuration from "./Configs";
import Execution from "./Execution";
import Synchronize from "./Synchronize";

const SectionWrapper: React.FC<{
  children: ReactNode;
  expanded: boolean;
  setCurrentSection: (section: number) => void;
  sectionNumber: number;
  maxSection: number;
  sectionName: string;
}> = ({
  children,
  expanded,
  setCurrentSection,
  sectionNumber,
  maxSection,
  sectionName,
}) => {
  const navigate = useNavigate();
  return (
    <div className="w-full">
      <div
        className="bg-white px-4 py-5 shadow sm:rounded-md sm:p-6"
        onClick={() => {
          if (!expanded) {
            setCurrentSection(sectionNumber);
          }
        }}
      >
        <div className={`${expanded ? "" : "hidden"}`}>{children}</div>
        {!expanded && (
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {sectionName}
          </h3>
        )}
        {expanded ? (
          <div className="flex justify-end pt-5">
            {sectionNumber > 0 && (
              <button
                //   type="button"
                onClick={() => setCurrentSection(sectionNumber - 1)}
                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:border-dwdarkblue focus:ring-offset-2"
              >
                Back
              </button>
            )}
            {sectionNumber < maxSection && (
              <button
                //   type="submit"
                onClick={() => setCurrentSection(sectionNumber + 1)}
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-dwlightblue py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-dwdarkblue focus:outline-none focus:ring-2 focus:ring-dwdarkblue focus:ring-offset-2"
              >
                Next
              </button>
            )}
            {sectionNumber === maxSection && (
              <button
                //   type="submit"
                onClick={() => {
                  navigate("/dashboard/materialize");
                }}
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-dwdarkblue focus:outline-none focus:ring-2 focus:ring-dwdarkblue focus:ring-offset-2"
              >
                Save
              </button>
            )}
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};
const MaterializeWizard: React.FC<{
  materializationConfig: MaterializationConfig | null;
}> = (props) => {
  const [currentSection, setCurrentSection] = useState(0);
  const { dag, project } = useProjectGlobals();
  if (!dag.isSuccess || !project.isSuccess) {
    return <Loading></Loading>;
  }
  const dagData = createLogicalDAG(dag.data as LogicalDAGBase);
  const projectData = project.data;
  const sections = [
    {
      name: "Configuration",
      component: <Configuration project={projectData} />,
    },
    {
      name: "Artifacts",
      component: <Artifacts project={projectData} dag={dagData} />,
    },
    {
      name: "Workflow",
      component: <Execution project={projectData} dag={dagData} />,
    },
    {
      name: "Launch",
      component: <Synchronize project={projectData} dag={dagData} />,
    },
  ];
  return (
    <div className="space-y-6 px-4">
      {sections.map((section, index) => {
        return (
          <SectionWrapper
            maxSection={sections.length - 1}
            setCurrentSection={setCurrentSection}
            sectionNumber={index}
            key={section.name}
            expanded={currentSection === index}
            sectionName={section.name}
          >
            {section.component}
          </SectionWrapper>
        );
      })}
    </div>
  );
};

export default MaterializeWizard;
