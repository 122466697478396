import { Menu, Transition } from "@headlessui/react";
import { ChevronRightIcon, HomeIcon } from "@heroicons/react/20/solid";
import { Fragment } from "react";
import { DiGitBranch } from "react-icons/di";
import { Link, useLocation } from "react-router-dom";
import { Project } from "../../state/api/backendApiRaw";
import { selectCurrentBranch, setBranch } from "../../state/branchSlice";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { classNames } from "../../utils";

const BranchSelector = (props: { project: Project; currentBranch: string }) => {
  const availableBranches = [
    props.project.default_branch,
    ...props.project.alternate_branches,
  ].filter((branch) => branch != props.currentBranch);
  const dispatch = useAppDispatch();
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex items-center ml-4 font-medium  hover:text-gray-800">
          {props.currentBranch}
          <DiGitBranch className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white 
        shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none cursor-pointer"
        >
          <div className="py-1">
            {availableBranches.map((branch) => {
              return (
                <Menu.Item key={branch}>
                  {({ active }) => (
                    <span
                      onClick={() => {
                        dispatch(setBranch(branch));
                      }}
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-800",
                        "block px-4 py-2 text-sm w-max"
                      )}
                    >
                      {branch}
                    </span>
                  )}
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
  // return (
  //   <Link to="/projects" className="ml-4 font-medium  hover:text-gray-800">
  //     {props.currentBranch}
  //   </Link>
  // );
};
export const NavBreadCrumb = (props: { project: Project }) => {
  const location = useLocation();

  // Relative to the dashboard so that we can view it
  const currentBranch =
    useAppSelector(selectCurrentBranch) || props.project.default_branch;
  let elements = [
    <Link
      key="projects"
      to="/projects"
      className="ml-4 font-medium  hover:text-gray-800"
    >
      {props.project.project_name}
    </Link>,
    <BranchSelector
      key="branch"
      project={props.project}
      currentBranch={currentBranch}
    />,
  ];
  const pathNameRelativeToDashboard = location.pathname.split("/").slice(2);
  elements = [
    ...elements,
    ...pathNameRelativeToDashboard.map((pathName) => (
      <span key={pathName} className="ml-4 font-medium hover:text-gray-800">
        {pathName}
      </span>
    )),
  ];
  {
    /* //   if (loc === "dashboard") {
  //     return [
  //       {
  //         name: props.project.project_name,
  //         href: "/projects", // TODO -- this should be on a project page
  //         current: false,
  //       },
  //       {
  //         name: currentBranch,
  //         href: "/projects",
  //         current: false,
  //       },
  //     ];
  //   }
  //   return [
  //     {
  //       name: loc,
  //       href: "/" + pathName.slice(0, i + 1).join("/"),
  //       current: i === pathName.length - 1,
  //     },
  //   ]; */
  }
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-4">
        <li>
          <div>
            <a href="#" className=" hover:text-gray-800">
              <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </a>
          </div>
        </li>
        {elements.map((element, index) => (
          <li key={index}>
            <div className="flex items-center">
              <ChevronRightIcon
                className="h-5 w-5 flex-shrink-0 "
                aria-hidden="true"
              />
              {element}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};
