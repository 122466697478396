import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    gitserverApiApiGetProjects: build.query<
      GitserverApiApiGetProjectsApiResponse,
      GitserverApiApiGetProjectsApiArg
    >({
      query: () => ({ url: `/api/projects` }),
    }),
    gitserverApiApiGetProject: build.query<
      GitserverApiApiGetProjectApiResponse,
      GitserverApiApiGetProjectApiArg
    >({
      query: (queryArg) => ({
        url: `/api/project`,
        params: { project_id: queryArg.projectId },
      }),
    }),
    gitserverApiApiGetDagFromProject: build.query<
      GitserverApiApiGetDagFromProjectApiResponse,
      GitserverApiApiGetDagFromProjectApiArg
    >({
      query: (queryArg) => ({
        url: `/api/dag_from_project`,
        params: { project_id: queryArg.projectId, branch: queryArg.branch },
      }),
    }),
    gitserverApiApiGetDagFromProjectWithConfig: build.query<
      GitserverApiApiGetDagFromProjectWithConfigApiResponse,
      GitserverApiApiGetDagFromProjectWithConfigApiArg
    >({
      query: (queryArg) => ({
        url: `/api/dag_from_project_with_config`,
        params: {
          branch: queryArg.branch,
          project_id: queryArg.projectId,
          config_name: queryArg.configName,
          config_overrides: queryArg.configOverrides,
        },
      }),
    }),
    gitserverApiApiGetExecutionsByProject: build.query<
      GitserverApiApiGetExecutionsByProjectApiResponse,
      GitserverApiApiGetExecutionsByProjectApiArg
    >({
      query: (queryArg) => ({
        url: `/api/executions_from_project`,
        params: { project_id: queryArg.projectId },
      }),
    }),
    gitserverApiApiGetRunLog: build.query<
      GitserverApiApiGetRunLogApiResponse,
      GitserverApiApiGetRunLogApiArg
    >({
      query: (queryArg) => ({
        url: `/api/run_log`,
        params: { run_id: queryArg.runId },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as backendApi };
export type GitserverApiApiGetProjectsApiResponse =
  /** status 200 OK */ Project[];
export type GitserverApiApiGetProjectsApiArg = void;
export type GitserverApiApiGetProjectApiResponse = /** status 200 OK */ Project;
export type GitserverApiApiGetProjectApiArg = {
  projectId: number;
};
export type GitserverApiApiGetDagFromProjectApiResponse =
  /** status 200 OK */ LogicalDag;
export type GitserverApiApiGetDagFromProjectApiArg = {
  projectId: number;
  branch?: string;
};
export type GitserverApiApiGetDagFromProjectWithConfigApiResponse =
  /** status 200 OK */ LogicalDag;
export type GitserverApiApiGetDagFromProjectWithConfigApiArg = {
  branch: string;
  projectId: number;
  configName: string;
  configOverrides: string;
};
export type GitserverApiApiGetExecutionsByProjectApiResponse =
  /** status 200 OK */ DagRun[];
export type GitserverApiApiGetExecutionsByProjectApiArg = {
  projectId: number;
};
export type GitserverApiApiGetRunLogApiResponse =
  /** status 200 OK */ DagRunWithRunLog;
export type GitserverApiApiGetRunLogApiArg = {
  runId: number;
};
export type DagConfig = {
  config: object;
  name: string;
};
export type Project = {
  id: number;
  git_repo: string;
  project_name: string;
  base_dag_path: string;
  configs: DagConfig[];
  time_modified: string;
  time_created: string;
  project_description: string;
  default_branch: string;
  alternate_branches: string[];
  exclude?: string[];
};
export type HamiltonFunction = {
  name: string;
  module: string[];
  contents: string;
  lineStart: number;
  lineEnd: number;
};
export type PythonType = {
  typeName: string;
};
export type Dependency = {
  type: PythonType;
  name: string;
  dependencyType: string;
};
export type HamiltonNode = {
  name: string;
  functionIdentifier: string[];
  dependencies: {
    [key: string]: Dependency;
  };
  documentation?: string;
  tags: object;
  namespace: string[];
  userDefined: boolean;
  returnType: PythonType;
};
export type LogicalDag = {
  functions: HamiltonFunction[];
  nodes: HamiltonNode[];
  config?: object;
  DAGRoot: string[];
};
export type DagRun = {
  id: number;
  run_id: string;
  status: "SUCCESS" | "FAILURE" | "RUNNING" | "UNINITIALIZED";
  project_version: string;
  start_time: string;
  end_time: string;
  project_id: number;
  run_log_id: number;
};
export type TaskRun = {
  node_name: string;
  status: "SUCCESS" | "FAILURE" | "RUNNING" | "UNINITIALIZED";
  start_time: string;
  end_time: string;
  result_type: string;
  result_summary?: object;
};
export type TaskData = {
  tasks: TaskRun[];
};
export type RunLog = {
  task_data: TaskData;
  task_data_schema: number;
};
export type DagRunWithRunLog = {
  id: number;
  run_id: string;
  status: "SUCCESS" | "FAILURE" | "RUNNING" | "UNINITIALIZED";
  project_version: string;
  start_time: string;
  end_time: string;
  project_id: number;
  run_log: RunLog;
};
export const {
  useGitserverApiApiGetProjectsQuery,
  useGitserverApiApiGetProjectQuery,
  useGitserverApiApiGetDagFromProjectQuery,
  useGitserverApiApiGetDagFromProjectWithConfigQuery,
  useGitserverApiApiGetExecutionsByProjectQuery,
  useGitserverApiApiGetRunLogQuery,
} = injectedRtkApi;
