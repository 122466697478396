import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import {
  createLogicalDAG,
  getFunctionIdentifier,
  LogicalDAGBase,
} from "../../../../hamilton/dagTypes";
import { useProjectGlobals } from "../../../../state/api/api";
import {
  HamiltonFunction,
  HamiltonNode,
  TaskRun,
} from "../../../../state/api/backendApiRaw";
import { Loading } from "../../../common/Loading";
import { RunStatus } from "../Run/Status";
import { GrNotes } from "react-icons/gr";

const TableRow: React.FC<{
  task: TaskRun;
  fn: HamiltonFunction | null;
  isHighlighted: boolean;
  setHighlighted: (highlighted: boolean) => void;
}> = ({ task, isHighlighted, setHighlighted, fn }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <tr
      id={`#${task.node_name}`}
      className={`${isHighlighted ? "bg-slate-100" : ""} h-12`}
      onMouseEnter={() => setHighlighted(true)}
      onMouseLeave={() => setHighlighted(false)}
    >
      {cols.map((col, index) => {
        const ToRender = col.render;
        return (
          <td key={index} className="py-2 px-3 text-sm max-w-sm text-gray-500">
            {<ToRender run={task} status={task.status} fn={fn} />}
          </td>
        );
      })}
    </tr>
  );
};
type RenderProps = {
  run: TaskRun;
  fn: HamiltonFunction | null;
  status: string;
};
const cols = [
  {
    displayName: "Task name",
    render: (props: RenderProps) => (
      // This is a little awkward -- we need to think through the best way to model this (UUIDs? Or just IDs?)
      <span>{props.run.node_name}</span>
    ),
  },
  {
    displayName: "Duration",
    render: (props: RenderProps) => {
      const startMillis = new Date(props.run.start_time).getTime();
      const endMillis = new Date(props.run.end_time).getTime();
      const durationSeconds = (endMillis - startMillis) / 1000;
      return (
        <span className="font-semibold">{durationSeconds.toFixed(3)}s</span>
      );
    },
  },
  {
    displayName: "Time Ran",
    render: (props: RenderProps) => {
      // TODO -- consolidate logic for this with getFunctionIdentifier in dagtypes
      // Moving too fast to care right now
      return <span>{new Date(props.run.start_time).toLocaleString()}</span>;
    },
  },
  // TODO -- include time zone support
  {
    displayName: "Status",
    render: (props: RenderProps) => {
      return <RunStatus status={props.run.status} />;
    },
  },
  {
    displayName: "Code",
    render: (props: RenderProps) => {
      return props.fn ? (
        <HashLink
          className="text-dwlightblue"
          to={`/dashboard/code/#${getFunctionIdentifier(props.fn).replace(
            ".",
            "_"
          )}`}
        >
          {getFunctionIdentifier(props.fn)}
        </HashLink>
      ) : (
        <span></span>
      );
    },
  },
  // This is where the summary data goes...
  {
    displayName: "Data",
    render: (props: RenderProps) => {
      return <div></div>;
    },
  },
  {
    displayName: "Logs",
    render: (props: RenderProps) => {
      return <GrNotes className="text-xl hover:scale-125 cursor-pointer" />;
    },
  },
];

export const TaskTable: FC<{
  tasks: TaskRun[];
  highlightedTask: string | null;
  setHighlightedTask: (task: string | null) => void;
}> = (props) => {
  const { dag } = useProjectGlobals();
  const dagData = dag.data;
  if (dagData === undefined) {
    return <Loading />;
  }
  const logicalDAG = createLogicalDAG(dag.data as LogicalDAGBase);
  // This is a hack due to the way we use nodes/map to functions
  // As multiple functions can create the same node, we need to resolve it somehow
  // We should think this through, but for demo purposes this is fine
  const nodeMap = new Map<string, HamiltonNode>();
  logicalDAG.nodes.forEach((node) => {
    nodeMap.set(node.name, node);
  });

  const getFunctionForNode = (node: string) => {
    const nodeData = nodeMap.get(node);
    if (nodeData === undefined) {
      return null;
    }
    return logicalDAG.getFunctionCreatingNode(nodeData);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-3 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr className="">
                  {cols.map((col, index) => (
                    <th
                      key={index}
                      scope="col"
                      className="py-3.5 pl-3 pr-3 text-left text-sm font-semibold text-gray-900"
                    >
                      {col.displayName}
                    </th>
                  ))}
                  {<th className="w-6"></th>}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {props.tasks.map((task, index) => {
                  return (
                    <TableRow
                      fn={getFunctionForNode(task.node_name)}
                      task={task}
                      key={index}
                      isHighlighted={task.node_name === props.highlightedTask}
                      setHighlighted={(highlighted) =>
                        props.setHighlightedTask(
                          highlighted ? task.node_name : null
                        )
                      }
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
