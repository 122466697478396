import React, { ReactNode } from "react";
import {
  getFunctionIdentifier,
  LogicalDAG,
} from "../../../../hamilton/dagTypes";
import { HamiltonNode, Project } from "../../../../state/api/backendApiRaw";
import { DropdownSelector } from "../../../common/Dropdown";
import NodeGroupingVisualizer from "../../../common/NodeGroupingVisualizer";

type OrchestrationEngine = {
  name: string;
  value: string;
  allowNodeGrouping: boolean;
};

const groupingStrategies = [
  {
    name: "Single Task",
    value: "single",
    description:
      "Groups everything into a single task. This is likely what you want if your data isn't big and your pipeline isn't slow.",
    groupingFunction: (node: HamiltonNode) => "task",
  },
  {
    name: "By Module",
    value: "module",
    description: "Groups nodes so that each module is a single task.",
    groupingFunction: (node: HamiltonNode) =>
      node.functionIdentifier.slice(0, -1).join(".") || "root",
  },
  {
    name: "By Node",
    value: "node",
    description:
      "Groups nodes so that each node is a single task. You don't want this.",
    groupingFunction: (node: HamiltonNode) => node.name,
  },
  {
    name: "By Function",
    value: "function",
    description:
      "Groups nodes so that each function is a single task. This is unlikely what you want unless you have very big/expensive functions.",
    groupingFunction: (node: HamiltonNode) =>
      node.functionIdentifier.join(".") || "root",
  },
  //   {
  //     name: "By Artifact (recommended)",
  //     value: "module",
  //     description:
  //       "Groups nodes so that each node produces a single artifact. May duplicate node execution if the same node leads to multiple artifacts.",
  //   },
];

const DEFAULT_GROUPING_STRATEGY = groupingStrategies[1];

const orchestrationEngines = [
  { name: "Airflow", value: "airflow", allowNodeGrouping: true },
  { name: "Prefect", value: "prefect", allowNodeGrouping: true },
  { name: "Local Run", value: "local", allowNodeGrouping: false },
];

const Execution: React.FC<{ project: Project; dag: LogicalDAG }> = (props) => {
  const [orchestrationEngine, setOrchestrationEngine] =
    React.useState<OrchestrationEngine | null>(null);
  const [groupingStrategy, setGroupingStrategy] = React.useState(
    DEFAULT_GROUPING_STRATEGY
  );

  const visualizers = new Map<string, ReactNode>();
  groupingStrategies.forEach((strategy) => {
    visualizers.set(
      strategy.name,
      <NodeGroupingVisualizer
        dag={props.dag}
        removeUserDefined={true}
        groupingFunction={strategy.groupingFunction}
      />
    );
  });
  return (
    <>
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Execution
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            How to run your DAG -- what workflow/orchestrator to use, how to
            group the nodes, and what the artifact will look like.
          </p>
        </div>

        <div className="mt-5 space-y-6 md:col-span-2 md:mt-0">
          <div>
            <DropdownSelector
              choices={orchestrationEngines}
              setCurrentChoice={setOrchestrationEngine}
              currentChoice={orchestrationEngine}
              title="Orchestration Engine"
            ></DropdownSelector>
          </div>
          <div>
            {orchestrationEngine?.allowNodeGrouping && (
              <>
                <DropdownSelector
                  choices={groupingStrategies}
                  setCurrentChoice={setGroupingStrategy}
                  currentChoice={groupingStrategy}
                  title="Grouping Strategy"
                />
                <p className="text-sm pt-2 text-gray-600">
                  {groupingStrategy.description}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
      {/* {orchestrationEngine && orchestrationEngine.allowNodeGrouping && (
        <div className="w-full h-[500px]">
          {visualizers.get(groupingStrategy.name)}
        </div>
      )} */}
      <div className="flex justify-end"></div>
    </>
  );
};

export default Execution;
