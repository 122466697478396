import { FC, ReactNode } from "react";
import {
  GitserverApiApiGetProjectsApiResponse,
  Project,
} from "../../state/api/backendApiRaw";

import { AiFillGithub, AiOutlinePlus, AiOutlineSave } from "react-icons/ai";

import { BiNetworkChart } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../state/hooks";
import { setProjectID } from "../../state/projectSlice";
import { useProjects } from "../../state/api/api";
import { Loading } from "../common/Loading";
import { setBranch, unsetBranch } from "../../state/branchSlice";

const textWithIcon = (
  classNames: string,
  text: string,
  icon: (classNames: string) => ReactNode
) => {
  return (
    <div className="flex flex-row items-center gap-2">
      {" "}
      {icon(classNames)} <p className={classNames}>{text}</p>
    </div>
  );
};

const getRepo = (gitURL: string) => {
  return gitURL.replace("github.com/", "");
};

const ProjectItem: FC<Project> = (props) => {
  /**
   * Displays a "project"
   * This is ugly and will be redeisgned using the principles in "refactoring UI"
   */
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const timeModified = new Date(props.time_modified);
  const timeCreated = new Date(props.time_created);
  const projectName = props.project_name;
  const projectGitRepo = props.git_repo;
  const projectDescription = props.project_description;
  return (
    <div
      onClick={() => {
        dispatch(setProjectID(props.id)); // TBD on whether I want to do this exactly? State could get a little out of sync...
        dispatch(unsetBranch());
        navigate("/dashboard/code");
        // TODO -- get loadDAGFromProject to also set Project, and call it "initializeWorkspace" or something like that
        // dispatch(loadDAGFromProject(props)), navigate("/dashboard");
      }}
      className={`h-28 w-192 hover:bg-dwdarkblue/5 hover:cursor-poin p-3 border-slate-200  border
       flex flex-row justify-between rounded-2xl`}
    >
      <div className="flex flex-col justify-between">
        <h1 className="text-2xl text-slate-600">
          {" "}
          {textWithIcon("", projectName, (c) => (
            <BiNetworkChart className={c} />
          ))}
        </h1>
        <p className="text-md text-slate-500 px-2"> {projectDescription}</p>
      </div>
      <div className="flex flex-col justify-between items-end text-slate-800">
        {textWithIcon("", getRepo(projectGitRepo), (c) => (
          <AiFillGithub className={c} />
        ))}
        <div className="flex flex-col justify-center">
          <div>
            <div className="flex flex-row gap-2 items-center justify-center">
              <AiOutlineSave />
              <span className="text-slate-500">
                {timeModified.toDateString()}
              </span>
            </div>
            <div className="flex flex-row gap-2 items-center justify-center">
              <AiOutlinePlus />
              <span className="text-slate-500">
                {timeCreated.toDateString()}
              </span>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

const ProjectsSelector: FC<{
  projects: GitserverApiApiGetProjectsApiResponse;
}> = (props) => {
  /**
   * Selector for projects
   * This was adapted from tailwind components.
   * Will likely redeisgn to use the refactoring design principles, etc...
   */
  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center gap-6  to-white/5">
      <div className=" bg-white shadow sm:rounded-md">
        <ul role="list" className="divide-y divide-gray-200">
          {props.projects.map((project, index) => (
            <li className="px-4 py-4 sm:px-6" key={index}>
              <ProjectItem {...project} key={index} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
const Projects = () => {
  const { data, isError, isFetching, isSuccess } = useProjects();

  if (isFetching) {
    return <Loading />;
  } else if (isError) {
    return <div>error</div>;
  } else if (isSuccess) {
    return <ProjectsSelector projects={data} />;
  } else {
    return <div className="">shrug</div>;
  }
};

export default Projects;
