import React, { useContext, useLayoutEffect, useRef, useState } from "react";
import ReactFlow, {
  PanOnScrollMode,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
} from "reactflow";
import { DagRunWithRunLog, TaskRun } from "../../../../state/api/backendApiRaw";
import { RunStatus } from "./Status";

type VizNode = {
  id: string;
  type: string;
  position: { x: number; y: number };
  data: { node: TaskRun; width: number; height: number };
  level: number;
};

type VizEdge = {
  id: string;
  source: string;
  target: string;
};

const convertToNodesAndEdges = (
  run: DagRunWithRunLog
): [VizNode[], VizEdge[]] => {
  const nodes: VizNode[] = run.run_log.task_data.tasks.map((taskRun) => ({
    id: taskRun.node_name,
    type: "custom",
    position: { x: 0, y: 0 },
    data: { node: taskRun, width: 0, height: 0 },
    level: 0,
  }));
  const edges: VizEdge[] = []; // TODO -- add visualization edges if we want them
  return [nodes, edges];
};

/**
 * Gets the color for a node
 * @param node Node to get the color for
 * @returns A tailwind bg-class reprseenting the color as well as its highlighted version
 */
const getColor = (node: TaskRun): [string, string] => {
  switch (node.status) {
    case "SUCCESS":
      return ["bg-green-500/50", "bg-green-500"];
    case "FAILURE":
      return ["bg-dwred/50", "bg-dwred"];
    case "UNINITIALIZED":
      return ["bg-gray-500/50", "bg-gray-500"];
    case "RUNNING":
      return ["bg-dwlightblue/50", "bg-dwlightblue"];
  }
};
function CustomNodeComponent({
  data,
}: {
  data: { node: TaskRun; width: number; height: number };
}) {
  console.log(data.height, data.width);
  const highlightedTask = useContext(HighlightedTaskContext);
  const selected = highlightedTask.highlightedTask === data.node.node_name;
  const color = getColor(data.node)[selected ? 1 : 0];
  return (
    <div
      onClick={() => {
        const node = document.getElementById(`#${data.node.node_name}`);
        node?.scrollIntoView({ behavior: "smooth", block: "center" });
      }}
      style={{ width: `${data.width}px`, height: `${data.height}px` }}
      className={`${color} px-1`}
    >
      {/* <NodeView type={data.nodeRole} node={data.node}></NodeView>
        <Handle
          className="!bg-dwdarkblue/70"
          type="target"
          position={Position.Left}
        />
        <Handle
          className="!bg-dwdarkblue/70"
          type="source"
          position={Position.Right}
        /> */}
    </div>
  );
}

const nodeTypes = { custom: CustomNodeComponent };

const parseDate = (date: string): number => {
  return Date.parse(date) * 1000 + parseInt(date.slice(-3));
};

const layoutWaterfallNodes = (
  initNodes: VizNode[],
  initEdges: VizEdge[],
  width: number,
  height: number
): [VizNode[], VizEdge[]] => {
  // TODO -- actually do a layout
  const minStartTime = Math.min(
    ...initNodes.map((node) => parseDate(node.data.node.start_time))
  );
  const maxEndTime = Math.max(
    ...initNodes.map((node) => parseDate(node.data.node.end_time))
  );
  const timeRange = maxEndTime - minStartTime;
  const nodeHeight = height / initNodes.length; // Try one per row
  const nodesWithLayout = initNodes.map((node, index) => {
    const startTime = parseDate(node.data.node.start_time);
    const endTime = parseDate(node.data.node.end_time);
    const xStart = ((startTime - minStartTime) / timeRange) * width;
    const xEnd = (Math.max(endTime - minStartTime, 1) / timeRange) * width;
    const y = nodeHeight * index;
    return {
      ...node,
      position: { x: xStart, y },
      data: { ...node.data, height: nodeHeight, width: xEnd - xStart },
    };
  });
  return [nodesWithLayout, initEdges];
};

type TaskRunTooltipProps = {
  taskRun: TaskRun | null; // When node is null, tooltip is hidden
  top: number;
  left: number;
};

const TaskRunTooltip = ({ taskRun, top, left }: TaskRunTooltipProps) => {
  const startTime = taskRun?.start_time;
  const endTime = taskRun?.end_time;
  const durationSeconds =
    startTime === undefined || endTime === undefined
      ? undefined
      : (parseDate(endTime) - parseDate(startTime)) / 1_000_000;

  return (
    <>
      <div
        className={`bg-white shadow-md rounded-md p-2 
            fixed z-40 max-w-xl pointer-events-none`}
        style={{ top: `${top}px`, left: `${left}px` }}
      >
        {taskRun && (
          <>
            <div className="text-xl font-semibold">{taskRun.node_name}</div>
            <div className="text-gray-800">{durationSeconds} seconds</div>
            <RunStatus status={taskRun.status}></RunStatus>
          </>
        )}
      </div>
    </>
  );
};

const HighlightedTaskContext = React.createContext<{
  highlightedTask: string | null;
}>({
  highlightedTask: null,
});

const WaterfallChart: React.FC<{
  run: DagRunWithRunLog;
  highlightedTask: string | null;
  setHighlightedTask: (task: string | null) => void;
}> = ({ run, highlightedTask, setHighlightedTask }) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const targetRef = useRef<HTMLDivElement>(null);
  const [initNodes, initEdges] = convertToNodesAndEdges(run);
  const [layoutedNodes, layoutedEdges] = layoutWaterfallNodes(
    initNodes,
    initEdges,
    dimensions.width,
    dimensions.height
  );
  const [tooltipProps, setTooltipProps] = useState<TaskRunTooltipProps>({
    taskRun: null,
    top: 0,
    left: 0,
  });
  const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);
  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.getBoundingClientRect().width,
        height: targetRef.current.getBoundingClientRect().height,
      });
    }
    setNodes(layoutedNodes);
  }, [targetRef.current]);
  console.log(layoutedNodes);
  return (
    <>
      {tooltipProps.taskRun && <TaskRunTooltip {...tooltipProps} />}

      <div className="w-full h-[500px] m-2 z-10" ref={targetRef}>
        <HighlightedTaskContext.Provider value={{ highlightedTask }}>
          <ReactFlowProvider>
            <ReactFlow
              nodesDraggable={false}
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              nodeTypes={nodeTypes}
              minZoom={0.1}
              maxZoom={100}
              // TODO -- donate/buy pro option when we have more revenue...
              proOptions={{ hideAttribution: true }}
              // fitView
              className=""
              onNodeMouseLeave={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setHighlightedTask(null);
                setTooltipProps({
                  taskRun: null,
                  top: 0,
                  left: 0,
                });
              }}
              // panOnScrollMode={PanOnScrollMode.Horizontal}
              onNodeMouseEnter={(e, node) => {
                e.stopPropagation();
                e.preventDefault();
                setHighlightedTask(node.data.node.node_name);
                setTooltipProps({
                  taskRun: node.data.node,
                  top: e.clientY,
                  left: e.clientX,
                });
              }}
            />
            {/* <MiniMap pannable={true} ariaLabel={null} position="bottom-left" /> */}
          </ReactFlowProvider>
        </HighlightedTaskContext.Provider>
      </div>
    </>
  );
};

export default WaterfallChart;
